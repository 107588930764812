import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { showApprovalAtom } from './utils/atoms';
import axios from 'axios';
import { FaTimesCircle } from 'react-icons/fa';

const ApprovalConfirmation = () => {
    const [showApproval, setShowApproval] = useAtom(showApprovalAtom);
    const [popup, setPopup] = useState(true)
    const [confirmation, setConfirmation] = useState(false);
    const [disapprove, setDisapprove] = useState(false);
    const [reason, setReason] = useState("");
    const productId = localStorage.getItem('productId');
    const [message, setMessage] = useState("");

    const approveProduct = async () => {
        try {
            const response = await axios.patch(`https://epagad.fly.dev/admin/approve-product?productId=${productId}`);
            if (response.status === 200) {
                setMessage(`Product has been approved successfully.`);
                setConfirmation(true)
            }
        }
        catch (error) {
            console.error(error);
            setMessage("An error occurred, please try again shortly.")
        }
    }

    const disapproveProduct = async (e) => {
        e.preventDefault()
        setDisapprove(false)
        try {
            const response = await axios.patch(`https://epagad.fly.dev/admin/disapprove-product?productId=${productId}`, { reason });
            if (response.status === 200) {
                setMessage(`Product has been disapproved successfully.`);
                setConfirmation(true)
            }
        }
        catch (error) {
            console.error(error);
            setMessage("An error occurred, please try again shortly.")
            setConfirmation(true)
        }
    }

    return (
        <div>
            {showApproval && (
                <div className='fixed inset-0 bg-black bg-opacity-30 h-screen backdrop-blur-sm flex justify-center items-center z-50'>
                    {popup && (
                        <div className='flex flex-col bg-white h-[230px] w-[380px] md:w-[500px] p-4'>
                            <div className='flex flex-col'>
                                <FaTimesCircle className='text-[23px] mb-4 cursor-pointer' onClick={() => setShowApproval(false)} />
                                <p className='text-black text-[15px] font-[500]'>
                                    Do you wish to approve this product? <br /> Note that an approved product cannot be disapproved
                                </p>

                                <div className='flex items-center gap-3 mt-14'>
                                    <button className='bg-[#722f37] p-2 text-white' onClick={() => {
                                        setPopup(false)
                                        setDisapprove(true);
                                    }}>
                                        Disapprove
                                    </button>

                                    <button className='text-green-500' onClick={() => {
                                        setPopup(false)
                                        approveProduct();
                                    }}>
                                        Approve
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {disapprove && (
                        <div className='flex flex-col bg-white h-[300px] w-[380px] md:w-[500px] p-4'>
                            <FaTimesCircle className='text-[23px] mb-4 cursor-pointer' onClick={() => setShowApproval(false)} />
                            <p>Reason for disapproval</p>

                            <form method='POST' onSubmit={disapproveProduct}>
                                <textarea
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    className='w-full h-[140px] border mt-2 p-2 text-[13px] bg-gray-100 focus:border-black'
                                />
                                <button
                                    type="submit"
                                    className='bg-[#722f37] text-white p-1 mt-3'
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    )}

                    {confirmation && (
                        <div className='flex flex-col bg-white h-[230px] w-[380px] md:w-[500px] p-4'>
                            <div className='flex flex-col'>
                                <FaTimesCircle className='text-[23px] mb-4 cursor-pointer' onClick={() => setShowApproval(false)} />
                                <p className='text-black text-[15px] font-[500] flex items-center justify-center h-[130px]'>
                                    {message}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ApprovalConfirmation;
