import React, { useEffect, useState } from 'react';
import { TiTimes } from "react-icons/ti";
import ButtonLoader from '../General/ButtonaLoader';
import { useAtom } from 'jotai';
import { showPopupAtom } from '../../Utils/atom';

const NewsLetterModal = () => {
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [showPopup, setShowPopup] = useAtom(showPopupAtom);
    const subscribed = localStorage.getItem('subscribedToNewsLetter');
    const lastPopupTime = localStorage.getItem('lastPopupTime');

    useEffect(() => {
        if (!subscribed && (!lastPopupTime || (Date.now() - lastPopupTime) > (24 * 60 * 60 * 1000))) {
            const timer = setTimeout(() => {
                setShowPopup(true);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [subscribed, lastPopupTime, setShowPopup]);

    useEffect(() => {
        const disableScroll = () => {
            document.body.style.overflow = 'hidden';
        };
        const enableScroll = () => {
            document.body.style.overflow = 'auto';
        };

        if (showPopup) disableScroll();
        else enableScroll();

        return () => {
            enableScroll();
        };
    }, [showPopup]);

    const Subscribe = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            console.log("about to subscribe")
            const response = await fetch("https://epagad.fly.dev/buyer/subscribe", {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    email: email
                })
            });
            console.log("subscribe")

            if (response.status === 201) {
                setIsLoading(false);
                localStorage.setItem('subscribedToNewsLetter', true);
                setResponseMessage("Thank you for subscribing!");
            }
        } catch (error) {
            setIsLoading(false);
            setResponseMessage("An error occurred, please try again later.");
            console.error("Error: ", error);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        localStorage.setItem('lastPopupTime', Date.now());
    };

    return (
        <div>
            {showPopup && !subscribed && (
                <div className='fixed inset-0 bg-black bg-opacity-70 h-screen backdrop:blur-sm flex justify-center items-center z-50'>
                    <div className='shadow-sm w-[500px] h-fit px-4 py-6 bg-white mx-4 md:mx-0'>
                        <div className='flex justify-between'>
                            <p className='text-[20px] font-[300]'>Subscribe to our NewsLetters</p>
                            <TiTimes className='text-[23px] font-[300] cursor-pointer' onClick={closePopup} />
                        </div>

                        <p className='mt-8 text-[13px] text-gray-400'>
                            Stay informed and never miss out! Subscribe to our newsletter to receive exclusive updates, special offers, and the latest news delivered directly to your inbox. Be the first to know about new product launches, promotions, and exciting events. Join our community today!
                        </p>

                        {responseMessage && <p className='text-center text-[#722f37] font-[500] mt-3 mb-3 text-sm'>{responseMessage}</p>}

                        <form method='POST' onSubmit={Subscribe} className='mt-12'>
                            <div className='flex flex-row justify-between border mb-6 h-[43px]'>
                                <input
                                    type='email'
                                    placeholder='Email Address'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete='on'
                                    required
                                    className='w-full text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border'
                                />
                            </div>

                            <div className='mt-6'>
                                <button
                                    type='submit'
                                    className='bg-[#722f37] text-white w-full h-[43px] flex items-center justify-center text-[15px] font-[500]'>
                                    {isLoading ? <ButtonLoader /> : <p>Subscribe</p>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewsLetterModal;
