import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Landing/Main";
import ProductDetail from "./components/Products/ProductDetail";
import Signup from "./components/Auth/Signup";
import ConfirmationPage from "./components/Auth/ConfirmationPage";
import Login from "./components/Auth/Login"
import AddProduct from './components/Products/AddProduct'
import ProtectedRoutes from "./Utils/ProtectedRoutes";
import ViewProducts from "./components/Products/ViewProducts";
import EditProduct from "./components/Products/EditProduct";
import axios from 'axios';
import { productsAtom } from './Utils/atom';
import { useAtom } from 'jotai';
import NotFound from "./components/General/NotFound";
import AdminLogin from "./components/Admin/Login";
import AdminSignup from "./components/Admin/Signup";
import ProtectedAdminRoutes from "./components/Admin/utils/ProtectedAdminRoutes";
import ViewSellers from "./components/Admin/ViewSellers";
import PendingProducts from "./components/Admin/PendingProducts";
import PaymentSuccess from "./components/Payment/PaymentSuccess";
import Account from "./components/Landing/Account";
import CustomerSupport from "./components/Landing/CustomerSupport";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import Loader from "./components/General/Loader";
import About from "./components/Landing/About";
import TermsAndConditions from "./components/Landing/TermsAndConditions";
import AddService from "./components/Services/AddService";


function App() {

  // api call to fetch all db products
  // this is done here to ensure that all products are loaded once the app mounts
  // to allow it persist across components
  const [products, setProducts] = useAtom(productsAtom);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get('https://epagad.fly.dev/product?category');

        if (response.status === 200 && Array.isArray(response.data.data)) {
          // console.log(response.data.data)
          {/* Reversed this for proper order of the products  */ }
          setProducts((response.data.data))
            ;
          console.log(products)
        }
      }
      catch (error) {
        return <Loader />
      }
    };

    getProducts();
  }, []);


  return (
    <div>
      <Router>
        <Routes>
          {/* user routes */}
          <Route element={<ProtectedRoutes />}>
            <Route element={<AddProduct />} path="/products/add" />
            <Route element={<AddService />} path="/services/add" />
            <Route element={<ViewProducts />} path="/products/:sellerId/view-all" />
            <Route element={<EditProduct />} path="/products/:sellerId/view-all/:productId/edit" />
            <Route element={<PaymentSuccess />} path="/payment" />
          </Route>
          <Route exact element={<Main />} path="/" />
          <Route exact element={<ProductDetail />} path="/products/:productId/detail" />
          <Route element={<Signup />} path="/signup" />
          <Route element={<ConfirmationPage />} path="/confirmation-page" />
          <Route element={<Login />} path="/login" />
          <Route element={<Account />} path="/account" />
          <Route element={<CustomerSupport />} path="/customer-support" />
          <Route element={<About />} path="/about" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route element={<NotFound />} path="*" />

          {/* admin routes */}
          <Route element={<ProtectedAdminRoutes />}>
            <Route element={<ViewSellers />} path="/control-panel/07D32q/admin/view-sellers" />
            <Route element={<PendingProducts />} path="/control-panel/07D32q/admin/pending-products" />
          </Route>
          <Route element={<AdminLogin />} path="/control-panel/07D32q/admin/login" />
          <Route element={<AdminSignup />} path="/control-panel/07D32q/admin/signup" />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
