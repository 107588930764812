import React from 'react'
import Loader from '../General/Loader'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

const ConfirmationPage = () => {
    const email = localStorage.getItem('email')
    const navigate = useNavigate()

    if (!email) {
        return <Loader />
    }

    return (
        <div className=''>
            <div className='flex items-center gap-2 cursor-pointer ml-4 mt-4 md:hidden' onClick={() => navigate(-1)}>
                <IoIosArrowBack size={15} className='flex items-center justify-center' />

                <p className='font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center'>Go back</p>
            </div>

            <div className='flex justify-center mt-24 mx-4 md:mx-0'>
                <p className='font-[600] text-center text-[13px] md:text-[24px]'>
                    Please click the link sent to {email} to verify your email address. <br />
                    <span className='text-[10px] md:text-[16px]'>If you don't see the email in your inbox, please check your spam folder.</span>
                </p>
            </div>

        </div>
    )
}

export default ConfirmationPage