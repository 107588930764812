import React, { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Header1 from './Header/Header1'
import Header2 from './Header/Header2'
import ButtonLoader from '../General/ButtonaLoader'

const CustomerSupport = () => {
    const navigate = useNavigate()
    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [message, setMessage] = useState("")
    const [response, setResponse] = useState("")
    const [isButtonLoading, setIsButtonLoading] = useState(false)


    const sendMessage = async (e) => {
        e.preventDefault()
        setIsButtonLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
            }
            const response = await fetch('https://epagad.fly.dev/support/', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    fullName: fullname,
                    phoneNumber: phoneNumber,
                    email: email,
                    comment: message
                })
            });
            if (response.status === 200) {
                setIsButtonLoading(false)
                setResponse("Thank you for contacting us!")
            }
        }
        catch (error) {
            setIsButtonLoading(false)
            setResponse("An error occurred. Please try again later.")
        }

    };

    return (
        <React.Fragment>
            <Header1 />
            <Header2 />
            <div className='min-h-screen'>
                <div className='flex border-b py-2 font-semibold'>
                    <p className="text-[#722f37] pl-4">Contact Support</p>
                </div>

                <div className='flex items-center gap-2 cursor-pointer ml-4 mt-4' onClick={() => navigate(-1)}>
                    <IoIosArrowBack size={15} className='flex items-center justify-center' />

                    <p className='font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center'>Go back</p>
                </div>

                <div className='flex flex-col gap-1 md:flex-row justify-center mt-10 mx-4'>
                    <div>
                        <p className='font-[700] text-[26px] md:text-[40px]'>For More Details <br /> Contact Us!</p>
                        <div className='border-2 w-[80px] md:w-[100px] border-[#722f37] mt-2' />


                        <div className='max-w-[500px] mt-8'>
                            <p className='text-[14px] md:text-[15px] md:text-base'>
                                Send our team a quick message with your questions, challenges and ideas and we'll get back to you as soon as possible.
                                We're pretty responsive and will try to respond in a few hours.
                            </p>

                            <div className='mt-4 md:mt-12 italic'>
                                <a href='mailto:info@epagadgroup.com' className='underline text-[13px] leading-[24px]'>
                                    info@epagadgroup.com
                                </a>

                                <p className='text-[13px] leading-[24px]'>
                                     +234 80-553-72852
                                </p>

                                <p className='text-[13px] leading-[24px]'>
                                    14 Olakanpo Street, Bodija Estate, Ibadan.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='mt-10 md:mt-0'>
                        {response && <p className='text-center mb-2 text-[13px] md:text-[15px] text-[#722f37] font-[600]'>{response}</p>}
                        <form className={`md:w-[470px] lg:w-[680px] md:mx-auto`} onSubmit={sendMessage}>

                            <label className="block text-sm font-semibold mb-1">Fullname:</label>
                            <input
                                type="text"
                                value={fullname}
                                onChange={(e) => setFullname(e.target.value)}
                                autoComplete="on"
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                            />

                            <label className="block text-sm font-semibold mb-1">Email:</label>
                            <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete='on'
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                            />

                            <label className="block text-sm font-semibold mb-1">Phone Number:</label>
                            <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                autoComplete="on"
                                required
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black placeholder:text-sm text-[13px]"
                            />

                            <label className="block text-sm font-semibold mb-1">Comment or Message:</label>
                            <textarea
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                autoComplete="on"
                                required
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black placeholder:text-sm text-[13px] h-[200px]"
                            />

                            <div className='mt-10 mb-6'>
                                <button
                                    type='submit'
                                    className='bg-[#722f37] text-white w-full h-[43px] flex items-center justify-center text-[15px] font-[500] mt-10 mb-4'>
                                    {isButtonLoading ? <ButtonLoader /> : <p>Submit</p>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default CustomerSupport