import React from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import { LuHeadphones } from "react-icons/lu";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { isAuthenticatedAtom } from '../../../Utils/atom';
import { useAtomValue } from 'jotai';


const Header4 = () => {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom)

  return (
    <React.Fragment>
      <div className=' left-0 right-0 z-50'>
        <div className='flex px-4 md:px-20 items-center py-4 border-b border-b-gray-200 pb-4 justify-between bg-white'>
          <div className='gap-1.5 md:gap-5 flex'>
            <Link to='/customer-support' className='flex text-black gap-1 items-center hover:underline duration-300'>
              <LuHeadphones className='text-[10px] md:text-[15px]' />
              <p className='text-[11px] md:text-[13px] font-[500]'>Contact support</p>

            </Link>

            <div className='flex text-black md:gap-1 items-center hover:underline duration-300'>
              <MdOutlinePhoneInTalk className='text-[10px] md:text-[15px]' />
              <p className='text-[11px] md:text-[13px] font-[500]'>+234 80-553-72852</p>

            </div>




          </div>


          {isAuthenticated && (
            <Link to='/products/add' className='flex font-[600] duration-500 items-center md:gap-1'>
              <p className='text-[10px] md:text-[12px]'>Manage My Adverts</p>
              <IoIosArrowForward />
            </Link>
          )}

        </div>
      </div>

    </React.Fragment >
  )
}

export default Header4
