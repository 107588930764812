import React, { useState } from "react";
import { signupSchema } from "./ValidationSchema";
import { FiEdit } from "react-icons/fi";
import { BsFillPersonFill } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { BiSolidLockAlt } from "react-icons/bi";
import { GiConfirmed } from "react-icons/gi";
import { FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import ButtonLoader from "../General/ButtonaLoader";
import toast, { Toaster } from "react-hot-toast";

const Signup = () => {
  const [userImage, setUserImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [location, setLocation] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState("");
  const [values, setValues] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    if (name === "password" && value.length < 6) {
      setErrors({
        ...errors,
        password: "Password must be at least 6 characters long",
      });
    } else {
      setErrors({ ...errors, password: "" });
    }
  };

  const profileImageMissing = () =>
    toast.error("Please upload a profile image", {
      duration: 8000,
      id: "profile",
      position: "bottom-center",
    });

  const credentialsExists = () =>
    toast.error("Credentials already in use", {
      duration: 8000,
      id: "credentials",
      position: "bottom-center",
    });

  const nigerianStates = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Abuja",
  ];

  const imageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUserImage(file);
    e.target.blur();
  };

  const Signup = async (e) => {
    e.preventDefault();
    setIsButtonLoading(true);

    if (values.confirmPassword !== values.password) {
      setIsButtonLoading(false);
      setErrorMessage("Passwords must match");
    }

    if (!userImage || userImage.length === 0) {
      setIsButtonLoading(false);
      profileImageMissing();
      setErrorMessage("Please upload an image");
      return;
    }

    const formData = new FormData();
    formData.append("image", userImage);
    formData.append("fullname", values.fullname);
    formData.append("password", values.password);
    formData.append("email", values.email);
    formData.append("location", location);
    formData.append("phoneNumber", values.phoneNumber);

    try {
      const response = await fetch("https://epagad.fly.dev/seller/signup", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        localStorage.setItem("email", values.email.toLowerCase());
        setIsButtonLoading(false);

        navigate("/confirmation-page");
      } else {
        if (response.status === 401) {
          setIsButtonLoading(false);
          credentialsExists();
          setErrorMessage("User with these credentials already exists.");
          return;
        }
        if (response.status === 409) {
          setIsButtonLoading(false);
          credentialsExists();
          setErrorMessage("Email already exists. Please log in.");
          return;
        }
        setIsButtonLoading(false);
      }
    } catch (error) {
      setIsButtonLoading(false);
      setErrorMessage("An error occurred, please try again later.");
    }
  };

  return (
    <div className="">
      <Toaster />
      <div
        className="flex items-center gap-2 cursor-pointer ml-4 mt-4 md:hidden"
        onClick={() => navigate(-1)}
      >
        <IoIosArrowBack
          size={15}
          className="flex items-center justify-center"
        />

        <p className="font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center">
          Go back
        </p>
      </div>

      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit">
          <Link
            to="/"
            className="text-[#722f37] flex items-center justify-center mt-4 mb-2 font-[600] text-[32px]"
          >
            Epagad
          </Link>

          {errorMessage && (
            <p className="text-center text-[#722f37] font-[500] mt-3 mb-3 text-sm">
              {errorMessage}
            </p>
          )}
          <form method="POST" onSubmit={Signup}>
            <div className="mx-6 md:ml-6 mt-8 flex flex-col justify-center md:justify-start items-center md:items-start">
              {userImage ? (
                <div className="w-[148px] h-[148px] bg-gray-200 flex justify-center items-\ overflow-hidden ">
                  <img
                    className="leading-4 object-cover w-[100%] h-[100%]"
                    src={userImage}
                    alt="profile image"
                  />
                </div>
              ) : (
                <div className="w-[148px] h-[148px] bg-gray-200 flex items-center justify-center">
                  <span className="flex items-center justify-center text-[12px]">
                    Upload your image
                  </span>
                </div>
              )}

              <div style={{ position: "relative" }}>
                {/* hidden file input for selecting a new image */}
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                  onChange={imageUpload}
                />

                <label
                  htmlFor="file-upload"
                  className="flex justify-center items-center ml-[118.5px]  z-10 mt-[-30px] relative border w-[30px] h-[30px] bg-white cursor-pointer"
                >
                  <FiEdit className="w-[20px] h-[20px]" />
                </label>
              </div>
            </div>

            <div className="mt-8 mx-auto">
              <div className="flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] ">
                <input
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  value={values.fullname}
                  onChange={handleChange}
                  autoComplete="on"
                  required
                  className="w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border"
                />

                <div className="bg-[#722f37] w-12 flex items-center justify-center">
                  <BsFillPersonFill className="text-white" size={25} />
                </div>
              </div>

              <div className="flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] ">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  autoComplete="on"
                  required
                  className="w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border"
                />

                <div className="bg-[#722f37] w-12 flex items-center justify-center">
                  <MdOutlineMailOutline className="text-white" size={25} />
                </div>
              </div>

              <div className="flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] ">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  autoComplete="off"
                  autoCorrect="off"
                  required
                  className="w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border"
                />

                <div className="bg-[#722f37] w-12 flex items-center justify-center">
                  <BiSolidLockAlt
                    className="text-white flex"
                    size={25}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between mx-6  mb-3 ">
                {errors.password && (
                  <p className="text-red-500 text-xs my-2">{errors.password}</p>
                )}
              </div>

              <div className="flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] ">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  autoComplete="off"
                  autoCorrect="off"
                  required
                  className="w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border"
                />

                <div className="bg-[#722f37] w-12 flex items-center justify-center">
                  <GiConfirmed
                    className="text-white"
                    size={25}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px]">
                <select
                  name="location"
                  className="w-full px-2 py-2 focus:outline-none focus:border-black text-[13px]"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                >
                  <option value="" disabled className="text-gray-300">
                    Location
                  </option>
                  {nigerianStates.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>

                <div className="bg-[#722f37] w-12 flex items-center justify-center">
                  <IoLocationSharp className="text-white" size={25} />
                </div>
              </div>

              <div className="flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] ">
                <input
                  type="phone"
                  placeholder="Phone number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  autoComplete="on"
                  required
                  className="w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border"
                />

                <div className="bg-[#722f37] w-12 flex items-center justify-center">
                  <FaPhoneAlt className="text-white" size={25} />
                </div>
              </div>
            </div>

            <div className="mt-6 mx-6 mb-6">
              <button
                type="submit"
                className="bg-[#722f37] text-white w-full md:w-[650px] h-[43px] flex items-center justify-center text-[15px] font-[500]"
              >
                {isButtonLoading ? <ButtonLoader /> : <p>Sign up</p>}
              </button>
            </div>
          </form>

          <p className="font-[400] text-[15px] leading-4 tracking-tight text-center pb-3">
            Already have an account?
            <span>
              <Link
                to="/login"
                className="text-[#722f37] font-[500] text-[13px] leading-4 tracking-tight hover:text-[#080e13]"
              >
                {" "}
                Sign in
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
