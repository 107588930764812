import React from 'react'
import { Link } from 'react-router-dom'
import { HiOutlineMailOpen } from "react-icons/hi";



const Header5 = () => {
  return (
    <React.Fragment>
      <div className=' left-0 right-0 z-50'>
        <div className='flex px-6 md:px-20 items-center justify-between py-2 border-b border-b-gray-200 bg-white  '>
          <div className='flex text-black md:gap-1 items-center hover:underline duration-300 '>
            <HiOutlineMailOpen className='text-[10px] md:text-[15px]' />
            <p className='text-[11px] md:text-[13px] font-[500]'>epagadcares@gmail.com</p>

          </div>


          <Link to='/about' className='flex text-black hover:underline duration-300  border-l-2 h-5 pl-2  border-black border-opacity-50'>
            <p className='text-[10px] md:text-[13px] font-[500]'>About</p>
          </Link>
        </div>
      </div>

    </React.Fragment >
  )
}

export default Header5
