import React, { useEffect, useState } from 'react'
import { BsFillPersonFill } from "react-icons/bs"
import { BiSolidLockAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ButtonLoader from '../General/ButtonaLoader'


const AdminSignup = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const navigate = useNavigate()

    const Signup = async (e) => {
        e.preventDefault()
        setIsButtonLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
            }
            const response = await axios.post('https://epagad.fly.dev/admin/', {
                username: username,
                password: password,
            }, {
                headers: headers
            });

            if (response.status === 201) {
                setIsButtonLoading(false)
                navigate('/control-panel/07D32q/admin/login')
            }
        }
        catch (error) {
            setIsButtonLoading(false)
            setErrorMessage("An error occurred. Please try again later.")
            console.error("error: ", error)
        }

    };


    return (
        <div>
            <div className='flex flex-col items-center justify-center h-screen'>
                <div className='md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit'>
                    <p className='text-[#722f37] flex items-center justify-center mt-4 mb-2 font-[600] text-[32px]'>
                        Epagad
                    </p>

                    {errorMessage && <p className='text-center text-[#722f37] font-[500] mt-3 mb-3 text-sm'>{errorMessage}</p>}
                    <form method='POST' onSubmit={Signup}>
                        <div className='mt-8'>
                            <div className='flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] '>
                                <input
                                    type='text'
                                    placeholder='Username'
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    autoComplete='on'
                                    required
                                    className='w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-200'
                                />

                                <div className='bg-[#722f37] w-12 flex items-center justify-center'>
                                    <BsFillPersonFill className='text-white' size={25} />
                                </div>
                            </div>

                            <div className='flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] '>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete='off'
                                    required
                                    className='w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-200'
                                />

                                <div className='bg-[#722f37] w-12 flex items-center justify-center'>
                                    <BiSolidLockAlt className='text-white flex' size={25} />
                                </div>
                            </div>

                        </div>

                        <div className='mt-6 mx-6 mb-6'>
                            <button
                                type='submit'
                                className='bg-[#722f37] text-white w-full md:w-[650px] h-[43px] flex items-center justify-center text-[15px] font-[500]'>
                                {isButtonLoading ? <ButtonLoader /> : <p>Sign up</p>}
                            </button>
                        </div>

                        <p className='font-[400] text-[15px] leading-4 tracking-tight text-center pb-3'>
                            Have an account?
                            <span>
                                <Link
                                    to='/control-panel/07D32q/admin/login'
                                    className='text-[#722f37] font-[500] text-[13px] leading-4 tracking-tight hover:text-[#080e13]'> Sign in
                                </Link>
                            </span>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AdminSignup