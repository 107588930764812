import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Loader from '../General/Loader';
import { useAtom, useAtomValue } from 'jotai';
import { sellersAtom } from './utils/atoms';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import axios from 'axios';
import { searchQueryAtom, searchResultAtom } from '../../Utils/atom';
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';
import Header3 from '../Landing/Header/Header3';
import Header4 from '../Landing/Header/Header4';

const ViewSellers = () => {
  const [sellers, setSellers] = useAtom(sellersAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sellersPerPage] = useState(12);
  const searchResult = useAtomValue(searchResultAtom)
  const searchQuery = useAtomValue(searchQueryAtom)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    const fetchSellers = async () => {
      try {
        const response = await axios.get('https://epagad.fly.dev/admin/all-sellers');
        if (response.status === 200 && Array.isArray(response.data.data)) {
          setSellers(response.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        return <Loader />
      }
    };

    fetchSellers();
  }, [setSellers]);


  // paginate sellers
  const indexOfLastSeller = currentPage * sellersPerPage;
  const indexOfFirstSeller = indexOfLastSeller - sellersPerPage;
  const currentSellers = sellers.slice(indexOfFirstSeller, indexOfLastSeller);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const displayedSellers = searchQuery ? searchResult : currentSellers

  return (
    <div className='min-h-screen'>
      <div className="flex flex-col">
        <Header1 />
        <Header2 />
        <Header3 />
        <Header4 />

        <div className="flex md:flex-row md:space-x-4">

          <div className='hidden md:flex '>
            <Navbar />
          </div>

          <div className="relative w-full">

            <div className='flex justify-between border-b py-2 px-4 font-semibold'>
              <p className="">Sellers</p>

              <div className='md:hidden absolute top-0 right-0'>
                <Navbar />
              </div>
            </div>


            <div>
              {currentSellers.length > 0 ? (
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 overflow-hidden gap-3 ml-4 md:ml-0 mr-4 mt-6'>
                  {displayedSellers.map((seller, index) => (
                    <div key={index} className='w-full cursor-pointer border shadow-sm shadow-gray-600 mb-3 object-cover h-fit p-2'>
                      <div className='flex gap-2'>
                        <div
                          style={{
                            width: '54px',
                            height: '54px',
                            borderRadius: '50%',
                            backgroundColor: '#D9D9D9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {seller.profileImage !== null && (
                            <img
                              className='rounded-full leading-4'
                              style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                              }}
                              src={seller.profileImage}
                            />
                          )}
                        </div>
                        <p className='flex items-center justify-center font-[500] text-[13px] max-w-[123px] '>{seller.fullname}</p>
                      </div>

                      <div className='mt-4'>
                        <p className='text-[11px] flex font-[600]'>Seller ID: {seller._id}</p>

                        <p className='font-[700] mt-10 text-[13px]'>Personal data</p>

                        <div className='flex gap-2 items-center mt-2 max-w-[10px]'>
                          <p className='text-[11px] font-[500]'>email:</p>
                          <p className='text-[10px] underline'>{seller.email}</p>
                        </div>


                        <div className='flex gap-2 items-center mt-2'>
                          <p className='text-[11px] font-[500]'>phone number:</p>
                          <p className='text-[10px]'>{seller.phoneNumber}</p>
                        </div>


                        <div className='flex gap-2 items-center mt-2'>
                          <p className='text-[11px] md:text-[10px] lg:text-[11px] font-[500]'>verification status:</p>
                          {seller.verified === true ? <p className='text-[10px] md:text-[9px] lg:text-[10px] text-green-500'>verified</p> : <p className='text-[10px] md:text-[9px] lg:text-[10px] text-[#722f37]'>verification pending</p>}
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className='flex items-center justify-center mt-24 bg-none shadow-none border-none text-sm'>There are no sellers available.</p>
              )}
            </div>

            {/* pagination buttons */}
            <div className="flex items-center justify-center between gap-16 text-[15px] my-6 underline bottom-0">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className='cursor-pointer disabled:cursor-not-allowed'
              >
                <IoIosArrowBack className='bg-[#722f37] text-white text-[30px] flex items-center justify-center' />
              </button>

              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastSeller >= sellers.length}
                className='cursor-pointer disabled:cursor-not-allowed'
              >
                <IoIosArrowForward className='bg-[#722f37] text-white text-[30px] flex items-center justify-center' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSellers;
