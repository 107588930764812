import Cookies from "js-cookie";

const ACCESS_TOKEN_COOKIE_NAME = 'adminAccessToken';


export const storeAdminAccessToken = (accessToken) => {
    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, { expires: 1 });
}

export const getAdminAccessToken = () => {
    return Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
};

export const removeAdminAccessToken = () => {
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
};