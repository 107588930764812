import React, { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useAtomValue } from "jotai";
import { productsAtom } from "../../Utils/atom";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoChatbubbleOutline } from "react-icons/io5";
import RelatedProducts from "./RelatedProducts";
import Loader from "../General/Loader";
import Header1 from "../Landing/Header/Header1";
import Header2 from "../Landing/Header/Header2";
import Header3 from "../Landing/Header/Header3";
import Header4 from "../Landing/Header/Header4";

const ProductDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const navigate = useNavigate();
  const productId = localStorage.getItem("productId");
  const products = useAtomValue(productsAtom);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPageLoading(false);
    }, 600);

    return () => clearTimeout(timeout);
  }, []);

  // retrieve selected product
  useEffect(() => {
    const filteredProduct = products.filter((p) => p._id === productId);
    setSelectedProduct(filteredProduct.length > 0 ? filteredProduct[0] : null);
  }, [products, productId]);
  //     if (!selectedProduct || !selectedProduct.images || !Array.isArray(selectedProduct.images)) {
  //         console.log("this product no get image")

  //       return <Loader />
  //   }
  if (!selectedProduct) {
    return <Loader />;
  }

  // carousel navigation buttons
  const goToPrevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedProduct.images.length - 1 : prevIndex - 1,
    );
  };
  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedProduct.images.length - 1 ? 0 : prevIndex + 1,
    );
  };
  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };

  const redirectToWhatsapp = () => {
    const whatsappUrl = `https://wa.me/${selectedProduct.phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <React.Fragment>
      <style>
        {`
                    .fade-in {
                        opacity: 1;
                        transition: opacity ease;
                    }
                    .fade-out {
                        opacity: 0;
                        pointer-events: none;
                        transition: opacity ease;
                    }
                `}
      </style>

      <div className={isPageLoading ? "fade-out" : "fade-in"}>
        <Header1 />
        <Header2 />
        <Header3 />
        <Header4 />

        <div
          className="flex items-center gap-2 cursor-pointer ml-4 mt-4"
          onClick={() => navigate(-1)}
        >
          <IoIosArrowBack
            size={15}
            className="flex items-center justify-center"
          />

          <p className="font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center">
            Go back
          </p>
        </div>

        <div className="mt-5 flex flex-col md:flex-row gap-8 justify-center md:mx-4 lg:mx-0">
          {/* Product image carousel */}
          <div className="w-full md:w-[50%]">
            {selectedProduct.images && (
              <div className="relative h-[363px] md:h-[400px] border shadow-sm shadow-gray-400 mx-4 mb-10 md:mx-0">
                {selectedProduct.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className={`absolute w-full h-full object-cover cursor-pointer transition-opacity ${
                      index === currentImageIndex ? "opacity-100" : "opacity-0"
                    }`}
                  />
                ))}

                <button
                  className="w-[47.81px] h-[47.81px] absolute top-1/2 left-0 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-70 duration-500 z-10 flex items-center justify-center rounded-full m-4"
                  onClick={goToPrevSlide}
                >
                  <IoIosArrowBack
                    size={25}
                    className="text-black bg-opacity-20 flex items-center justify-center"
                  />
                </button>

                <button
                  className="w-[47.81px] h-[47.81px] absolute top-1/2 right-0 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-70 duration-500 z-10 flex items-center justify-center rounded-full m-4"
                  onClick={goToNextSlide}
                >
                  <IoIosArrowForward
                    size={25}
                    className="text-black bg-opacity-20 flex items-center justify-center"
                  />
                </button>
              </div>
            )}

            {selectedProduct.images && (
              <div className="relative left-0 right-0 flex justify-center -mt-8">
                {selectedProduct.images.map((_, index) => (
                  <button
                    key={index}
                    className={`mx-2 w-3 h-3 rounded-full duration-500 bg-white bg-opacity-70 hover:bg-opacity-100 focus:outline-none focus:bg-white ${
                      index === currentImageIndex ? "bg-white" : ""
                    }`}
                    onClick={() => goToSlide(index)}
                  ></button>
                ))}
              </div>
            )}

            <div className="bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg  mx-4 md:mx-0">
              <p className="text-[20px] md:text-[24px] font-[600]">
                Description
              </p>

              <p className="mt-4 text-[12px]">{selectedProduct.description}</p>
            </div>

            {selectedProduct.additional ? (
              <div className="bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg  mx-4 md:mx-0 my-2">
                <p className="text-[12px] md:text-[12px] font-[600]">
                  Additional Information
                </p>
                <p className="mt-4 text-[10px]">{selectedProduct.additional}</p>
              </div>
            ) : null}

            {selectedProduct.years ? (
              <div className="flex space-x-3 items-center  bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg  mx-4 md:mx-0 my-2">
                <p className="text-[9px] md:text-[12px] font-[600]">
                  Years of Experience{" "}
                </p>
                <p className="text-[14px] font-[500]">
                  {selectedProduct.years}
                </p>
              </div>
            ) : null}
          </div>

          {/* Product details */}
          <div className="w-full md:w-fit">
            <div className="bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg mx-4 md:mx-0 mb-4">
              <div className="flex justify-between items-center text-[10px]">
                {selectedProduct.price ? (
                  <p className="font-[700] text-[28px] md:text-[34px] leading-[36px]">
                    &#8358; {selectedProduct.price}
                  </p>
                ) : selectedProduct.priceRange > 0 ? (
                  <p className="font-[500] text-[15px] md:text-[15px] leading-[15px]">
                    Range: {selectedProduct.priceRange}
                  </p>
                ) : (
                  <p className="font-[500] text-[15px] md:text-[15px] leading-[36px]">
                    Contact seller for Price
                  </p>
                )}

                {selectedProduct.negotiable && (
                  <p className="font-[600] text-[12px]">
                    {selectedProduct.negotiable}
                  </p>
                )}
              </div>

              <div className="flex justify-between items-center my-3 gap-2 w-full">
                {selectedProduct.status ? (
                  <div className="border border-gray-300 rounded-lg w-full">
                    <p className="flex items-center justify-center py-2 text-[13px]">
                      Status - {selectedProduct.status}
                    </p>
                  </div>
                ) : (
                  console.log(selectedProduct)
                )}

                <div className="border border-gray-300 rounded-lg w-full">
                  <p className="flex items-center justify-center py-2 text-[13px]">
                    {selectedProduct.location}
                  </p>
                </div>
              </div>
              {selectedProduct.quantity ? (
                <div className="border border-[#722f37] rounded-lg flex items-center justify-center">
                  <p className="py-2 text-[13px]">
                    Quantity available - {selectedProduct.quantity}
                  </p>
                </div>
              ) : null}
            </div>

            <div className="bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg mx-4 md:mx-0 mb-4">
              <div className="flex gap-2">
                <div
                  style={{
                    width: "54px",
                    height: "54px",
                    borderRadius: "50%",
                    backgroundColor: "#D9D9D9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {selectedProduct.profileImage !== null && (
                    <img
                      className="rounded-full leading-4"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                      src={selectedProduct.profileImage}
                    />
                  )}
                </div>
                <p className="flex items-center justify-center font-[500] text-[13px] max-w-[123px] ">
                  {selectedProduct.fullname}
                </p>
              </div>

              <div className="mt-4">
                <div className="flex gap-2 items-center mt-2">
                  <p className="text-[11px] font-[500]">email:</p>
                  <p className="text-[10px] underline">
                    {selectedProduct.email}
                  </p>
                </div>

                <div className="flex gap-2 items-center mt-2">
                  <p className="text-[11px] font-[500]">verification status:</p>

                  {selectedProduct.verified === true ? (
                    <p className="text-[11px] text-green-500">verified</p>
                  ) : (
                    <p className="text-[11px] text-[#722f37]">
                      verification pending
                    </p>
                  )}
                </div>

                <div className="flex gap-2 items-center mt-2">
                  <p className="text-[11px] font-[500]">
                    product approval status:
                  </p>

                  {selectedProduct.approved === true ? (
                    <p className="text-[11px] text-green-500">approved</p>
                  ) : (
                    <p className="text-[11px] text-[#722f37]">
                      approval pending
                    </p>
                  )}
                </div>
              </div>

              <div
                className="flex border border-[#722f37] bg-[#722f37] rounded-lg items-center justify-center mt-4 gap-2 duration-500 cursor-pointer"
                onClick={() => setShowContact(!showContact)}
              >
                <MdOutlinePhoneInTalk className="text-[20px] text-white" />

                {showContact ? (
                  <p className="py-2 text-[14px] text-white font-bold">
                    {selectedProduct.phoneNumber}
                  </p>
                ) : (
                  <p className="py-2 text-[14px] text-white font-bold">
                    Show contact card
                  </p>
                )}
              </div>

              <div
                className="flex border border-red-700 rounded-lg text-[#722f37] items-center justify-center mt-4 gap-2 duration-500 cursor-pointer"
                onClick={redirectToWhatsapp}
              >
                <IoChatbubbleOutline className="text-[20px]" />
                <p className="py-2 text-[14px]">Message Seller</p>
              </div>
            </div>

            <div className="bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg mx-4 md:mx-0 mb-4">
              <a
                href="mailto:info@epagadgroup.com"
                className="border border-[#722f37] rounded-lg flex items-center justify-center py-2 text-[13px]"
              >
                Report Abuse
              </a>
            </div>

            <div className="bg-gray-100 h-fit p-4 shadow-sm shadow-gray-100 rounded-lg mx-4 md:mx-0 mb-4">
              <p className="font-[500] text-[18px] flex items-center justify-center">
                Safety Tips
              </p>
              <p className="text-[13px] leading-[24px]">
                &#x2022; Ensure a seller is verified before contacting them
              </p>
              <p className="text-[13px] leading-[24px]">
                &#x2022; Don't pay before seeing the product
              </p>
              <p className="text-[13px] leading-[24px]">
                &#x2022; Only pay if you're satisfied
              </p>
              <p className="text-[13px] leading-[24px]">
                &#x2022; Don't hesitate to report an abuse if you feel you have{" "}
                <br className="hidden lg:flex" /> been violated by a seller
              </p>
            </div>
          </div>
        </div>

        <div className="mx-4">
          <RelatedProducts />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductDetail;

