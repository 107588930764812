import React, { useEffect, useState } from "react"
import { PaystackButton } from "react-paystack"
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../../Utils/Cookies";
import axios from "axios";
import { useAtom, useAtomValue } from "jotai";
import { paymentReferenceAtom, paymentTypeAtom } from "../../Utils/atom";

const Payment = () => {
  const publicKey = 'pk_live_41db8223c52754f060ec91b2ecaaa872c535ae9b'
  const userDataString = localStorage.getItem('userData');
  const userData = JSON.parse(userDataString);
  const [amount, setAmount] = useState(0)
  const email = userData.email
  const fullname = userData.fullname
  const phoneNumber = userData.phoneNumber
  const navigate = useNavigate()
  const accessToken = getAccessToken()
  const paymentType = useAtomValue(paymentTypeAtom)

  useEffect(() => {
    if (paymentType === "Monthly") {
      setAmount(500000)
    }
    else {
      setAmount(5500000)
    }
  }, [paymentType])

  const handleSuccessPayment = async (reference) => {
    try {
      const currentDate = new Date().toISOString();
      const response = await fetch('https://epagad.fly.dev/payment/success', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reference: reference,
          paymentType: paymentType,
          firstPaymentDate: currentDate
        })
      });

      if (response.ok) {
        const responseData = await response.json();
        userData.premium = responseData.seller.premium;
        userData.firstPaymentDate = responseData.seller.firstPaymentDate
        localStorage.setItem('userData', JSON.stringify(userData));
      }
    } catch (error) {
      console.error("error: ", error);
      console.log("an error occurred");
    }
  };

  const componentProps = {
    email,
    amount,
    metadata: {
      fullname,
      phoneNumber,
    },
    publicKey,
    text: "Make Payment",
    onSuccess: (response) => {
      const params = new URLSearchParams();
      params.append('ref', response.reference);
      params.append('status', 'success');

      const queryString = params.toString();
      navigate(`/payment?${queryString}`);

      handleSuccessPayment(response.reference)
    },
    onClose: () => { return; },
  }

  return (
    <div>
      <PaystackButton {...componentProps} />
    </div>
  )
}

export default Payment




