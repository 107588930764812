import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import { isAuthenticatedAtom } from '../../../Utils/atom';
import { useAtom } from 'jotai';
import { removeAccessToken, getAccessToken } from '../../../Utils/Cookies';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAdminAccessToken, removeAdminAccessToken } from '../../Admin/utils/Cookies';
import { isAdminAuthenticatedAtom } from '../../Admin/utils/atoms';


const Header1 = () => {
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useAtom(isAdminAuthenticatedAtom)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      setIsAuthenticated(false);
    }
  }, [setIsAuthenticated]);

  useEffect(() => {
    const adminAccessToken = getAdminAccessToken()
    if (!adminAccessToken) {
      setIsAdminAuthenticated(false)
    }
  }, [setIsAdminAuthenticated])

  const handleSignout = () => {
    if (location.pathname.startsWith('/control-panel')) {
      removeAdminAccessToken()
      setIsAdminAuthenticated(false)
    }
    else {
      removeAccessToken()
      setIsAuthenticated(false)
    }
    navigate('/')
  };

  return (
    <React.Fragment>
      <div className='top-0 left-0 right-0 z-50'>
        <div className='bg-black flex justify-between px-4 md:px-20 items-center'>
          <p className='text-white md:font-bold text-[12px] md:text-[16px] py-3'>Welcome to Epagad Marketplace</p>

          {isAuthenticated ? (
            <div>
              <button
                onClick={handleSignout}
                className='bg-white flex items-center justify-center p-1 md:p-2 hover:bg-yellow-100 duration-500 gap-1'>
                <p className='text-black text-[10px] md:text-[12px] font-[500]'>Sign Out</p>
                <IoIosArrowForward size={10} />
              </button>
            </div>
          ) : (
            <div className='my-4 flex'>
              <Link to='/signup' className='bg-white flex items-center justify-center p-1 md:p-2 hover:bg-yellow-100 duration-500 gap-1'>
                <p className='text-black text-[10px] md:text-[12px] font-[500]'>Sign Up</p>
                <IoIosArrowForward size={10} />
              </Link>
              <div className='border border-l mx-2 md:mx-6' />
              <Link to='/login' className='flex items-center'>
                <p className='text-white hover:text-yellow-100 duration-500 text-[10px] md:text-[12px]'>Sign In</p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header1
