import { useAtomValue } from 'jotai';
import React from 'react'
import { MdVerified } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { paymentReferenceAtom } from '../../Utils/atom';
import { BsArrowReturnRight } from "react-icons/bs";

const PaymentSuccess = () => {
    const paymentReference = useAtomValue(paymentReferenceAtom)

    return (
        <div className='flex flex-col items-center justify-center mt-12'>
            <RiVerifiedBadgeFill className='text-[100px] md:text-[150px] text-[#722f37]' />
            <p className='text-center flex items-center justify-center mt-4 md:text-[20px] mx-4 md:mx-0'>Your payment has been approved successfully and your account would be updated shortly. You are now subscribed to a premium account.</p>


            <Link to='/' className='my-5 text-[#722f37] duration-300 text-[15px] p-2 w-fit mt-16 flex items-center gap-2'>
                <BsArrowReturnRight />
                <p>Return to Home</p>
            </Link>
        </div>
    )
}

export default PaymentSuccess