import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdOutlinePending } from "react-icons/md";
import { FaTimes, FaBars } from 'react-icons/fa';


const Navbar = () => {
    const location = useLocation()
    const [nav, setNav] = useState(false)

    return (
        <React.Fragment>
            <div className='hidden md:flex flex-col bg-[#F6F8FA] pb-10 w-[260px] min-h-screen'>
                <div className='flex flex-col ml-6 my-2'>
                    <p className='font-[600] text-[20px]'>Admin Dashboard</p>


                    <div className='flex flex-col mt-16'>
                        <Link
                            to='/control-panel/07D32q/admin/view-sellers'
                            className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-3 duration-500 ${location.pathname === '/control-panel/07D32q/admin/view-sellers' ? 'bg-gray-300' : 'bg-none'}`}
                        >
                            <FaUsersViewfinder size={20} />
                            <p className='flex items-center text-[13px]'>View sellers</p>

                        </Link>



                        <Link
                            to='/control-panel/07D32q/admin/pending-products'
                            className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-3 duration-500 ${location.pathname.startsWith('/control-panel/07D32q/admin/pending-products') ? 'bg-gray-300' : 'bg-none'}`}
                        >
                            <MdOutlinePending size={20} />
                            <p className='flex items-center text-[13px]'>Pending products</p>
                        </Link>

                    </div>
                </div>
            </div>


            {/* navbar for smaller screens */}
            <div className='md:hidden relative'>
                <div onClick={() => setNav(!nav)} className='flex justify-end md:hidden cursor-pointer z-20 py-2 px-4 duration-300'>
                    {nav ? <FaTimes size={20} className='text-black' /> : <FaBars size={20} className='text-gray-500' />}
                </div>

                {nav && (
                    <div className='inset-0 bg-black bg-opacity-30 h-screen backdrop:blur-sm flex justify-center items-center z-50 w-screen'>
                        <div className='flex flex-col mt-9 z-10 transition-all ease-in-out duration-500 absolute top-0 right-0 bg-gray-100 p-4 shadow-lg h-fit w-[250px] '>

                            <div className='flex flex-col mt-4'>

                                <Link
                                    to='/control-panel/07D32q/admin/view-sellers'
                                    className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-4 ${location.pathname === '/control-panel/07D32q/admin/view-sellers' ? 'bg-gray-300' : ''}`}
                                >
                                    <FaUsersViewfinder size={18} />
                                    <p className='flex items-center text-[11px]'>View sellers</p>

                                </Link>

                                <Link
                                    to='/control-panel/07D32q/admin/pending-products'
                                    className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-4 ${location.pathname === 'control-panel/07D32q/admin/pending-products' ? 'bg-gray-300' : ''}`}
                                >
                                    <MdOutlinePending size={18} />
                                    <p className='flex items-center text-[11px]'>Pending products</p>

                                </Link>

                            </div>
                        </div>
                    </div>

                )}
            </div>
        </React.Fragment >

    )
}

export default Navbar