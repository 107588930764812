import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { getAccessToken } from "../../Utils/Cookies";
import { useAtomValue } from "jotai";
import toast, { Toaster } from "react-hot-toast";
import {
  productCategoriesAtom,
  priceNegotiabilityAtom,
  productStatusAtom,
  productsAtom,
  paymentTypeAtom,
} from "../../Utils/atom";

import { nigerianStates } from "../../Utils/nigerianStates";
import axios from "axios";
import { useAtom } from "jotai";
import Payment from "../Payment/PaystackPayment";
import ButtonLoader from "../General/ButtonaLoader";
import Header1 from "../Landing/Header/Header1";
import Header2 from "../Landing/Header/Header2";
import Header3 from "../Landing/Header/Header3";
import Header4 from "../Landing/Header/Header4";

const AddService = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const sellerID = userData._id;
  const isPremiumSubscriber = userData.premium;
  const products = useAtomValue(productsAtom);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [years, setYears] = useState("");
  const [category, setCategory] = useState("");
  const [additional, setAdditional] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [response, setResponse] = useState("");
  const accessToken = getAccessToken();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [paymentType, setPaymentType] = useAtom(paymentTypeAtom);

  const handleNegotiableChange = (event) => {
    console.log("Negotiable event : ", event.target.checked);
    setIsNegotiable(event.target.checked);
  };

  const serviceAddedSuccess = () =>
    toast.success("Service Added Successfully", {
      duration: 8000,
      id: "service_added_success",
      position: "bottom-center",
    });
  const serviceAddedFailure = () =>
    toast.error("An error occured", {
      duration: 8000,
      id: "service_added_failure",
    });

  const formatOption = (state, lga) => `${state}, ${lga}`;

  const sellerProducts = products.filter(
    (product) => product.seller === sellerID,
  );

  useEffect(() => {});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonClicked(true);
    setIsButtonLoading(true);

    const serviceData = {
      description: description,
      priceRange: price,
      years: years,
      additional: additional,
      location: location,
      serviceCategory: category,
      negotiable: isNegotiable,
    };

    try {
      console.log(serviceData);
      const response = await axios.post(
        "https://epagad.fly.dev/service",
        serviceData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      console.log("response data: ", response.data);
      if (response.status === 201) {
        serviceAddedSuccess();
        setIsButtonLoading(false);
        setResponse("Your service has been sent successfully");
        setDescription("");
        setPrice("");
        setYears("");
        setStatus("");
        setLocation("");
        setAdditional("");
        setCategory("");
      }
    } catch (error) {
      serviceAddedFailure();
      setIsButtonLoading(false);
      setButtonClicked(false);
      setResponse("Service was not added, please try again.");
      console.log("error uploading product: ", error);
    }
  };

  return (
    <div className="flex flex-col">
      <Toaster />
      <Header1 />
      <Header2 />
      <Header3 />
      <Header4 />
      <div className="flex md:flex-row md:space-x-4">
        <div className="hidden md:flex ">
          <Navbar />
        </div>

        <div className="w-full">
          <div className="md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit h-fit relative mt-4 ">
            <div className="flex justify-between border-b py-2 px-4 font-semibold">
              <p className="">New Service</p>

              <div className="md:hidden absolute top-0 right-0">
                <Navbar />
              </div>
            </div>

            {/*  {sellerProducts.length >= 2 && isPremiumSubscriber === false ? (
              <div className="flex flex-col items-center justify-center">
                <p className="mt-12 md:mt-20 px-4 text-[14px] text-center">
                  You cannot add more services unless you are subscribed to a
                  premium account. <br /> <br />
                  Subscribe to premium by making a monthly payment of{" "}
                  <span className="font-[600] text-black">&#8358;5000</span> or
                  a yearly payment of{" "}
                  <span className="font-[600] text-black">&#8358;55,000</span>{" "}
                  <br />
                  Note: This is a non-recurring payment.
                </p>

                <div className="flex gap-6 mt-12 mb-6">
                  <button
                    onClick={() => setPaymentType("Monthly")}
                    className={`border border-[#722f37] p-2 ${
                      paymentType === "Monthly" ? "text-[#722f37]" : ""
                    } text-[13px] md:text-[15px]`}
                  >
                    Pay &#8358;5000/month
                  </button>

                  <button
                    onClick={() => setPaymentType("Yearly")}
                    className={`border border-[#722f37] p-2  ${
                      paymentType === "Yearly" ? "text-[#722f37]" : ""
                    } text-[13px] md:text-[15px]`}
                  >
                    Pay &#8358;55,000/year
                  </button>
                </div>

                {paymentType && (
                  <div className="text-white my-4 bg-green-500 hover:bg-green-800 duration-300 text-[13px] p-2 w-fit:">
                    <Payment />
                  </div>
                )}
              </div>
            ) : ( */}
            <div>
              {response && (
                <p className="ml-2 text-[10px] md:text-[11px] text-[#722f37] p-2 font-[600] md:max-w-[50vw] ">
                  {response}
                </p>
              )}
              <form
                className={`mt-4 p-4 md:w-[470px] lg:w-[680px] md:mx-auto`}
                onSubmit={handleSubmit}
              >
                <label className="block text-sm font-semibold mb-1">
                  Service Description:
                </label>
                <label className="block text-sm  mb-1 text-gray-500">
                  What do you offer ?{" "}
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  autoComplete="on"
                  required
                  className="min-h-48 w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                />
                <label className="block text-sm font-semibold mb-1">
                  Years of Experience :
                </label>
                <input
                  type="text"
                  value={years}
                  onChange={(e) => setYears(e.target.value)}
                  autoComplete="on"
                  required
                  className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                />

                <label className="block text-sm font-semibold mb-1">
                  Price Range:
                </label>
                <input
                  type="text"
                  placeholder="Optional"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  autoComplete="on"
                  className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                />

                <div className="flex space-x-1 my-2 items-center">
                  <p className="text-[13px]">Negotiable</p>
                  <input
                    type="checkbox"
                    id="negotiable"
                    checked={isNegotiable}
                    onChange={handleNegotiableChange}
                  />
                </div>

                <label className="block text-sm font-semibold mb-1">
                  Service Category:
                </label>
                <input
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  autoComplete="on"
                  className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                />

                <label className="block text-sm font-semibold mb-1">
                  Location of availability:
                </label>
                <select
                  name="location"
                  className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  required
                >
                  <option value=""></option>
                  {Object.entries(nigerianStates).map(([state, lgas]) =>
                    lgas.map((lga) => (
                      <option
                        key={`${state}-${lga}`}
                        value={formatOption(state, lga)}
                      >
                        {formatOption(state, lga)}
                      </option>
                    )),
                  )}{" "}
                </select>

                <label className="block text-sm font-semibold mb-1">
                  Additional Info:
                </label>
                <input
                  type="text"
                  placeholder="eg : available nationwide"
                  value={additional}
                  onChange={(e) => setAdditional(e.target.value)}
                  autoComplete="on"
                  className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                />

                <div className="mt-10 mb-6">
                  <button
                    type="submit"
                    disabled={buttonClicked}
                    className="bg-[#722f37] text-white w-full h-[43px] flex items-center justify-center text-[15px] font-[500] mt-10"
                  >
                    {isButtonLoading ? <ButtonLoader /> : <p>Save</p>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddService;
