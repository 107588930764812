import React, { useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAccessToken } from '../../Utils/Cookies';
import ButtonLoader from '../General/ButtonaLoader';
import Header1 from './Header/Header1';
import Header2 from './Header/Header2';

const Account = () => {
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const [response, setResponse] = useState("")
    const [fullname, setFullname] = useState(userData.fullname)
    const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber)
    const [profileImage, setProfileImage] = useState(userData.profileImage)
    const email = userData.email
    const sellerID = userData._id
    const verificationStatus = userData.verified
    const navigate = useNavigate()
    const accessToken = getAccessToken()

    const imageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setProfileImage(file);
        e.target.blur();
    };

    const UpdateAccount = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true)

        const formData = new FormData();
        formData.append('image', profileImage);
        formData.append('fullname', fullname);
        formData.append('phoneNumber', phoneNumber);

        try {
            const response = await axios.patch('https://epagad.fly.dev/seller/update', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                },
            });
            userData.fullname = response.data.seller.fullname
            userData.phoneNumber = response.data.seller.phoneNumber
            userData.profileImage = response.data.seller.profileImage

            localStorage.setItem('userData', JSON.stringify(userData));

            setIsButtonLoading(false)
            setResponse("Your account has been updated successfully.")
        }
        catch (error) {
            setIsButtonLoading(false)
            setResponse('An error occurred, please try again later.');
        }
    };


    return (
        <React.Fragment>
            <Header1 />
            <Header2 />

            <div className='min-h-screen'>
                <div className='flex border-b py-2 font-semibold'>
                    <p className="text-[#722f37] ml-4">Account</p>
                </div>

                <div className='flex items-center gap-2 cursor-pointer ml-4 mt-4' onClick={() => navigate(-1)}>
                    <IoIosArrowBack size={15} className='flex items-center justify-center' />

                    <p className='font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center'>Go back</p>
                </div>



                {response && <p className='text-center text-[11px] md:text-[15px] text-[#722f37] p-2 font-[600]'>{response}</p>}
                <form className={`p-4 md:w-[470px] lg:w-[680px] md:mx-auto`} onSubmit={UpdateAccount}>
                    <div className='mt-4 mb-3 flex flex-col justify-center md:justify-start items-center md:items-start'>

                        {profileImage ? (
                            <div className='w-[148px] h-[148px] bg-gray-200 flex justify-center items-\ overflow-hidden '>
                                <img
                                    className='leading-4 object-cover w-[100%] h-[100%]'
                                    src={profileImage}
                                    alt='profile image'
                                />
                            </div>
                        ) : (
                            <div className='w-[148px] h-[148px] bg-gray-200 flex items-center justify-center'>
                                <span className='flex items-center justify-center text-[12px]'>Upload your image</span>
                            </div>
                        )}
                        <div style={{ position: 'relative' }}>
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    opacity: 0,
                                    cursor: 'pointer',
                                    objectFit: 'cover',
                                }}
                                onChange={imageUpload}
                            />

                            <label htmlFor="file-upload" className='flex justify-center items-center ml-[118.5px]  z-10 mt-[-30px] relative border w-[30px] h-[30px] bg-white cursor-pointer'>
                                <FiEdit className='w-[20px] h-[20px]' />
                            </label>
                        </div>
                    </div>


                    <label className="block text-sm font-semibold mb-1">Seller ID:</label>
                    <div className='w-full px-3 py-2 border-b border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px] text-black'>
                        {sellerID}
                    </div>


                    <label className="block text-sm font-semibold mb-1">Verification Status:</label>

                    <div className={`w-full px-3 py-2 border-b border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px] text-black ${verificationStatus === true ? 'text-green-700' : ' text-[#722f37]'}`}>
                        {verificationStatus === true ? <p>true</p> : <p>false</p>}
                    </div>


                    <label className="block text-sm font-semibold mb-1">Fullname:</label>
                    <input
                        type="text"
                        value={fullname}
                        onChange={(e) => setFullname(e.target.value)}
                        autoComplete="on"
                        className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    />


                    <label className="block text-sm font-semibold mb-1">Email:</label>
                    <input
                        type="text"
                        value={email}
                        disabled
                        className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    />


                    <label className="block text-sm font-semibold mb-1">Phone Number:</label>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        autoComplete="on"
                        className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black placeholder:text-sm text-[13px]"
                    />

                    <div className='mt-10 mb-6'>
                        <button
                            type='submit'
                            className='bg-[#722f37] text-white w-full h-[43px] flex items-center justify-center text-[15px] font-[500] mt-3'>
                            {isButtonLoading ? <ButtonLoader /> : <p>Update account</p>}
                        </button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Account