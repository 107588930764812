import React, { useState, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { productsAtom } from '../../Utils/atom';
import { useNavigate } from 'react-router-dom';

const RelatedProducts = () => {
    const productId = localStorage.getItem('productId');
    const products = useAtomValue(productsAtom);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const selectedProduct = products.find(p => p._id === productId);

        if (selectedProduct) {

            const filteredProducts = products.filter(p => p.category === selectedProduct.category && p._id !== productId);
            const approvedProducts = filteredProducts.filter(p => p.approved === true && p.category !== "service")
            setRelatedProducts(approvedProducts);
        }
        else {
            setRelatedProducts([]);
        }
    }, [products, productId]);

    const NavigateToProductDetail = (productId) => {
        localStorage.setItem('productId', productId);
        navigate(`/products/${productId}/detail`);
    };


    return (
        <div className='mt-10'>
            <p className='font-[500] text-[16px] md:text-[20px] mb-3 text-[#722f37]'>Related products</p>

            {relatedProducts.length === 0 ? (
                <p className='md:text-base w-full text-[12px] my-8 font-[300]'>There are no related products for the selected item</p>
            ) : (
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 overflow-hidden gap-3'>
                    {relatedProducts.map((product, index) => (
                        <div key={index} className='w-full cursor-pointer' onClick={() => NavigateToProductDetail(product._id)}>
                            <div className='border shadow-sm shadow-gray-600 mb-3 object-cover h-fit'>
                                {product.images && product.images[0] && (
                                    <img
                                        src={product.images[0]}
                                        style={{
                                            objectFit: 'cover',
                                            width: '100%',
                                            height: '130px',
                                        }}
                                        className='object-cover w-full h-[110px]'
                                        alt={`apartment ${index + 1}`}
                                    />
                                )}

                                <div className='bg-white h-fit border-t'>
                                    <div className='py-3 mx-2'>
                                        <div className='h-[30px]'>
                                            <p className='text-[10px] md:text-[13px] truncate max-h-[38px] font-[500]'>
                                                {product.description}
                                            </p>
                                        </div>

                                        <div className='flex'>
                                            <p className='text-[10px] text-gray-500 font-[500]'>{product.category}</p>
                                        </div>

                                        <div className=''>
                                            <p className='text-[15px] font-[500] text-[#722f37]'>&#8358; {product.price}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default RelatedProducts;
