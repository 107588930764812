import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { IoAddCircleOutline } from "react-icons/io5";
import { RiUserAddLine } from "react-icons/ri";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { BsArrowReturnRight } from "react-icons/bs";
import { FaTimes, FaBars } from 'react-icons/fa';
import { MdOutlineVerifiedUser } from "react-icons/md";


const Navbar = () => {
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const profileImage = userData.profileImage
    const fullname = userData.fullname
    const sellerID = userData._id
    const email = userData.email
    const phoneNumber = userData.phoneNumber
    const verificationStatus = userData.verified
    const premium = userData.premium
    const paymentDate = userData.paymentDate
    const location = useLocation()
    const [nav, setNav] = useState(false)

    useEffect(() => {
        const disableScroll = () => {
            document.body.style.overflow = 'hidden';
        };
        const enableScroll = () => {
            document.body.style.overflow = 'auto';
        };

        if (nav) disableScroll();
        else enableScroll();

        return () => {
            enableScroll();
        };
    }, [nav]);


    return (
        <React.Fragment>
            <div className='hidden md:flex flex-col min-h-screen  bg-[#F6F8FA] pb-10 w-[260px]'>
                <div className='flex flex-col my-2'>
                    <div className='flex gap-2 ml-6'>
                        <div
                            style={{
                                width: '54px',
                                height: '54px',
                                borderRadius: '50%',
                                backgroundColor: '#D9D9D9',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {profileImage !== null && (
                                <img
                                    className='rounded-full leading-4'
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                    }}
                                    src={profileImage}
                                />
                            )}
                        </div>

                        <div className='flex gap-1 items-center'>
                            <p className='flex items-center justify-center font-[500] text-[13px] max-w-[123px] '>{fullname}</p>
                            {premium === true && (
                                <MdOutlineVerifiedUser className='text-[20px]' />
                            )}

                        </div>

                    </div>

                    <div className='mt-4 ml-6'>
                        <p className='font-[700] mt-10 text-[13px]'>Personal data</p>

                        <div className='flex gap-2 mt-2'>
                            <p className='text-[11px] font-[500]'>email:</p>
                            <p className='text-[10px] underline' style={{ maxWidth: '170px', overflowWrap: 'break-word' }}>{email}</p>
                        </div>


                        <div className='flex gap-2 items-center mt-2'>
                            <p className='text-[11px] font-[500]'>phone number:</p>
                            <p className='text-[10px]'>{phoneNumber}</p>
                        </div>


                        <div className='flex gap-2 items-center mt-2'>
                            <p className='text-[11px] font-[500]'>verification status:</p>
                            {verificationStatus === true ? <p className='text-[11px] text-green-500'>verified</p> : <p className='text-[11px] text-[#722f37]'>verification pending</p>}
                        </div>

                        {paymentDate && (
                            <div className='flex gap-2 items-center mt-2'>
                                <p className='text-[11px] font-[500]'>premium since:</p>
                                <p className='text-[11px]'>{paymentDate}</p>
                            </div>
                        )}

                    </div>


                    <div className='flex flex-col mt-16'>
                        <Link
                            to='/products/add'
                            className={`flex items-center gap-1 hover:bg-gray-200 py-2 pl-6 mb-3 duration-500 ${location.pathname === '/products/add' ? 'bg-gray-300' : 'bg-none'}`}
                        >
                            <IoAddCircleOutline size={20} />
                            <p className='flex items-center text-[13px]'>Add new product</p>

                        </Link>

                        <Link
                            to='/services/add'
                            className={`flex items-center gap-1 hover:bg-gray-200 py-2 pl-6 mb-3 duration-500 ${location.pathname === '/services/add' ? 'bg-gray-300' : 'bg-none'}`}
                        >
                            <RiUserAddLine size={20} />
                            <p className='flex items-center text-[13px]'>Add new service</p>

                        </Link>


                        <Link
                            to={`/products/${sellerID}/view-all`}
                            className={`flex items-center gap-1 hover:bg-gray-200 py-2 pl-6 mb-3 duration-500 ${location.pathname.startsWith(`/products/${sellerID}/view-all`) ? 'bg-gray-300' : 'bg-none'}`}
                        >
                            <MdOutlineProductionQuantityLimits size={20} />
                            <p className='flex items-center text-[13px]'>View all products</p>
                        </Link>

                        <Link
                            to='/'
                            className='flex items-center gap-1 hover:bg-gray-200 py-2 pl-6 mb-3 duration-500'
                        >
                            <BsArrowReturnRight size={20} />
                            <p className='flex items-center text-[13px]'>Return to home</p>
                        </Link>
                    </div>
                </div>
            </div>


            {/* navbar for smaller screens */}
            <div className='md:hidden relative'>
                <div onClick={() => setNav(!nav)} className='flex justify-end md:hidden cursor-pointer z-20 py-2 px-4 duration-300'>
                    {nav ? <FaTimes size={20} className='text-black' /> : <FaBars size={20} className='text-gray-500' />}
                </div>

                {nav && (
                    <div className='bfixed inset-0 bg-black bg-opacity-30 h-screen backdrop:blur-sm flex justify-center items-center z-50 w-screen'>
                        <div className='flex flex-col mt-9 z-10 transition-all ease-in-out duration-500 absolute top-0 right-0 bg-gray-100 shadow-lg h-screen w-[250px] '>
                            <div className='flex gap-2 p-3'>
                                <div
                                    style={{
                                        width: '54px',
                                        height: '54px',
                                        borderRadius: '50%',
                                        backgroundColor: '#D9D9D9',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {profileImage !== null && (
                                        <img
                                            className='rounded-full leading-4'
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '50%',
                                            }}
                                            src={profileImage}
                                        />
                                    )}
                                </div>

                                <div className='flex gap-1 items-center'>
                                    <p className='flex items-center justify-center font-[500] text-[13px] max-w-[123px] '>{fullname}</p>
                                    {premium === true && (
                                        <MdOutlineVerifiedUser className='text-[20px]' />
                                    )}

                                </div>

                            </div>

                            <div className='mt-4 p-3'>
                                <p className='font-[700] text-[13px]'>Personal data</p>

                                <div className='flex gap-2 mt-2'>
                                    <p className='text-[11px] font-[500]'>email:</p>
                                    <p className='text-[10px] underline' style={{ maxWidth: '170px', overflowWrap: 'break-word' }}>{email}</p>
                                </div>


                                <div className='flex gap-2 items-center mt-2'>
                                    <p className='text-[11px] font-[500]'>phone number:</p>
                                    <p className='text-[10px]'>{phoneNumber}</p>
                                </div>


                                <div className='flex gap-2 items-center mt-2'>
                                    <p className='text-[11px] font-[500]'>verification status:</p>
                                    {verificationStatus === true ? <p className='text-[11px] text-green-500'>verified</p> : <p className='text-[11px] text-[#722f37]'>verification pending</p>}
                                </div>

                            </div>


                            <div className='flex flex-col mt-8'>

                                <Link to='/products/add' className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-4 ${location.pathname === '/products/add' ? 'bg-gray-300' : ''}`}>

                                    <IoAddCircleOutline size={18} />
                                    <p className='flex items-center text-[11px]'>Add new product</p>

                                </Link>

                                <Link to='/services/add' className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-4 ${location.pathname === '/services/add' ? 'bg-gray-300' : ''}`}>

<RiUserAddLine size={18} />
<p className='flex items-center text-[11px]'>Add new service</p>

</Link>


                                <Link to={`/products/${sellerID}/view-all`} className={`flex items-center gap-1 hover:bg-gray-200 p-2 mb-4 ${location.pathname === `/products/${sellerID}/view-all` ? 'bg-gray-300' : ''}`}>

                                    <MdOutlineProductionQuantityLimits size={18} />
                                    <p className='flex items-center text-[11px]'>View all products</p>

                                </Link>

                                <Link to='/' className='flex items-center gap-1 hover:bg-gray-200 p-2 mb-4'>

                                    <BsArrowReturnRight size={18} />
                                    <p className='flex items-center text-[11px]'>Return to home</p>

                                </Link>

                            </div>
                        </div>
                    </div>

                )}
            </div>
        </React.Fragment >

    )
}

export default Navbar