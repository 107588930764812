import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { getAccessToken } from './Cookies'
import { useAtomValue } from 'jotai';
import { isAuthenticatedAtom } from './atom';

const ProtectedRoutes = () => {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes