import React from 'react';

const NotFound = () => {
    return (
        <div className='flex flex-col justify-center items-center mt-24'>
            <p className='text-[24px] md:text-[50px] flex leading-[60px] font-[600]'>(404) Not Found</p>
            <p className='text-[14px] md:text-[20px] flex font-[500]'>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;
