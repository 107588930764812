import React, { useState } from 'react';
import Navbar from './Navbar';
import { useAtom, useAtomValue } from 'jotai';
import { productsAtom, searchQueryAtom, searchResultAtom } from '../../Utils/atom';
import { useNavigate } from 'react-router-dom';
import { showApprovalAtom } from './utils/atoms';
import ApprovalConfirmation from './ApprovalConfirmation';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';
import Header3 from '../Landing/Header/Header3';
import Header4 from '../Landing/Header/Header4';

const PendingProducts = () => {
    const products = useAtomValue(productsAtom);
    const searchResult = useAtomValue(searchResultAtom)
    const searchQuery = useAtomValue(searchQueryAtom)
    const [showApprovalConfirmation, setShowApprovalConfirmation] = useAtom(showApprovalAtom);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(16);
    const navigate = useNavigate();

    const pendingProducts = products.filter(product => product.approved === false);
    const displayedProducts = searchQuery ? searchResult : pendingProducts

    const NavigateToProductDetail = (productId) => {
        localStorage.setItem('productId', productId);
        navigate(`/products/${productId}/detail`);
    }

    const NavigateToConfirmation = (productId) => {
        localStorage.setItem('productId', productId);
        setShowApprovalConfirmation(true);
    }

    //paginate products
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = displayedProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className='min-h-screen'>
            <div className={`flex flex-col ${showApprovalConfirmation ? 'filter blur-sm' : ''}`}>
                <Header1 />
                <Header2 />
                <Header3 />
                <Header4 />

                <div className="flex md:flex-row md:space-x-4">
                    <div className='hidden md:flex'>
                        <Navbar />
                    </div>

                    <div className="relative w-full">
                        <div className='flex items-center justify-between py-2 px-4 font-semibold'>
                            <p className="">Products awaiting approval <br className='md:hidden' /> <span className='text-[#722f37] text-[13px]'>(refresh page to remove already approved products)</span></p>

                            <div className='md:hidden absolute top-0 right-0'>
                                <Navbar />
                            </div>
                        </div>

                        <div>
                            {pendingProducts && pendingProducts.length > 0 ? (
                                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mr-4 ml-4 mt-6'>

                                    {currentProducts.map((product, index) => (
                                        <div key={index} className='w-full cursor-pointer'>
                                            <div className='border shadow-sm shadow-gray-600 mb-3 object-cover h-fit'>
                                                {product.images && product.images[0] && (
                                                    <img
                                                        src={product.images[0]}
                                                        style={{
                                                            objectFit: 'cover',
                                                            width: '100%',
                                                            height: '130px',
                                                        }}
                                                        className='object-cover w-full h-[110px]'
                                                        alt={`apartment ${index + 1}`}
                                                    />
                                                )}
                                                <div className='bg-white h-fit border-t'>
                                                    <div className='mt-2 mx-4'>
                                                        <div className='h-[30px]'>
                                                            <p className='text-[10px] md:text-[13px] truncate max-h-[38px] font-[500]'>
                                                                {product.description}
                                                            </p>
                                                        </div>

                                                        <div className='flex'>
                                                            <p className='text-[10px] text-gray-500 font-[500]'>{product.category}</p>
                                                        </div>

                                                        <div className=''>
                                                            <p className='text-[15px] font-[500] text-[#722f37]'>&#8358; {product.price}</p>
                                                        </div>

                                                        <div className='my-2 flex justify-between'>
                                                            <button className='text-black text-[12px] underline' onClick={() => NavigateToProductDetail(product._id)}>
                                                                Preview
                                                            </button>

                                                            <button className='text-black font-semibold text-[12px]' onClick={() => NavigateToConfirmation(product._id)}>
                                                                Approval
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className='flex items-center justify-center mt-24 bg-none shadow-none border-none text-sm'>There are no pending products to be approved.</p>
                            )}

                            {/* pagination buttons */}
                            <div className={`flex items-center justify-center between gap-16 text-[15px] my-6 underline`}>
                                <button
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className='cursor-pointer disabled:cursor-not-allowed'
                                >
                                    <IoIosArrowBack className='bg-[#722f37] text-white text-[30px] flex items-center justify-center' />
                                </button>

                                <button
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={indexOfLastProduct >= pendingProducts.length}
                                    className='cursor-pointer disabled:cursor-not-allowed'
                                >
                                    <IoIosArrowForward className='bg-[#722f37] text-white text-[30px] flex items-center justify-center' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showApprovalConfirmation && (
                <div className='fixed inset-0 flex items-center justify-center z-50'>
                    <ApprovalConfirmation />
                </div>
            )}
        </div >
    )
}

export default PendingProducts;
