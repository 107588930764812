import React from 'react'
import { ClipLoader } from 'react-spinners';


const Loader = () => {
    return (
        <div className='flex justify-center items-center mx-auto h-screen text-[#722f37]'>
            <ClipLoader
                color='#722f37'
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                className='loader'
            />
        </div>
    )
}

export default Loader