import React from 'react'
import { MoonLoader } from 'react-spinners';

const ButtonLoader = () => {
    return (
        <div className='flex justify-center items-center mx-auto h-screen text-[#722f37]'>
            <MoonLoader
                color='#FFFFFF'
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
                className='loader'
            />
        </div>
    )
}

export default ButtonLoader