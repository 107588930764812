import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { IoFilterCircleOutline } from "react-icons/io5";
import { TbCategory2 } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { useAtom, useAtomValue } from "jotai";
import { productCategoriesAtom, selectedCategoryAtom } from "../../Utils/atom";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const productCategories = useAtomValue(productCategoriesAtom);

  const updateCategpry = (category) => {
    setSelectedCategory(category);
  };

  return (
    <React.Fragment>
      <div className="hidden md:flex flex-col min-h-screen bg-[#F6F8FA] overflow-y-auto pb-10">
        <div className="flex flex-col my-10">
          <div
            className="ml-5 px-3 py-2 cursor-pointer w-fit mt-6 ml-1 pr-20 hover:bg-gray-300"
            onClick={() => updateCategpry("Services")}
          >
            <p className="text-[15px] font-semibold">Services</p>
          </div>

          <div
            className="ml-5 px-3 py-2 cursor-pointer w-fit mt-6 mb-2   pr-20 bg-gray-100"
            onClick={() => setSelectedCategory("All categories")}
          >
            <p className="text-[15px] font-semibold">Products</p>
          </div>

          <div className="flex items-center text-gray-500 my-2 pl-6">
            <IoFilterCircleOutline className="text-[18px]" />
            <p className="text-[12px] lg:text-[13px]lg:max-w-[50px]">
              Filter by category
            </p>
          </div>

          <div className="bg-gray-300 px-6 py-2 cursor-pointer w-fit mt-3 ml-6">
            <p
              className="text-[12px] font-semibold"
              onClick={() => setSelectedCategory("All categories")}
            >
              All categories
            </p>
          </div>

          <div className="flex flex-col mt-6">
            {productCategories.map((category, index) => (
              <div
                key={index}
                onClick={() => updateCategpry(category)}
                className="flex gap-3 mb-8 pl-6 pr-1 hover:bg-gray-200 duration-500 active:bg-gray-200 hover:py-2 cursor-pointer"
              >
                <TbCategory2 className="flex items-center font-semibold" />

                <p className="text-[14px] -mt-0.5">{category}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
