import React, { useState } from 'react'
import { FaKey } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import ButtonLoader from '../General/ButtonaLoader';
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';

const ForgotPassword = () => {
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [email, setEmail] = useState("")
    const navigate = useNavigate()

    const Submit = async (e) => {
        e.preventDefault()
        setIsButtonLoading(true)

        const headers = {
            'Content-Type': 'application/json'
        }
        try {
            const response = await fetch("https://epagad.fly.dev/password/reset-password", {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    email: email
                })
            })
            if (response.status === 200) {
                setIsButtonLoading(false)
                localStorage.setItem("email", email)
                navigate('/confirmation-page')
            }
            if (response.status === 404) {
                setIsButtonLoading(false)
                setErrorMessage("Please enter a valid email address")
            }
        }
        catch (error) {
            setIsButtonLoading(false)
            setErrorMessage("An error occurred. Please try again")
        }
    }

    return (
        <div>
            <Header1 />
            <Header2 />


            <div className='md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit flex flex-col mx-auto mt-10'>
                <div className='flex gap-2 md:text-[18px] font-[500] items-center justify-start md:mt-5 pl-6'>
                    <FaKey className='text-[#722f37]' />
                    <p>Forgot Password</p>
                </div>

                <div className='border-gray-200 mt-4 border mx-6' />

                <p className='font-[300] mt-5 text-center'>
                    Enter your Email Address
                </p>

                {errorMessage && <p className='text-center text-[#722f37] font-[500] mt-3 mb-3 text-sm'>{errorMessage}</p>}
                <form onSubmit={Submit}>
                    <div className='mt-8'>
                        <div className='flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] '>
                            <input
                                type='email'
                                placeholder='Email Address'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete='on'
                                required
                                className='w-full text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border'
                            />
                        </div>
                    </div>

                    <div className='mt-6 mx-6 mb-6'>
                        <button
                            type='submit'
                            className='bg-[#722f37] text-white w-full md:w-[650px] h-[43px] flex items-center justify-center text-[15px] font-[500]'>
                            {isButtonLoading ? <ButtonLoader /> : <p>Submit</p>}
                        </button>

                    </div>

                    <div className='mt-6 mx-6 mb-6'>
                        <button
                            onClick={() => navigate('/login')}
                            className='text-[#722f37] flex items-center justify-center mx-auto text-[13px] font-[500]'>
                            Cancel
                        </button>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default ForgotPassword