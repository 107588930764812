import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Header1 from './Header/Header1'
import Header2 from './Header/Header2'

const About = () => {
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <Header1 />
            <Header2 />
            <div className='min-h-screen'>
                <div className='flex border-b py-2 font-semibold'>
                    <p className="text-[#722f37] pl-4">About</p>
                </div>

                <div className='flex items-center gap-1 cursor-pointer mx-4 mt-4' onClick={() => navigate(-1)}>
                    <IoIosArrowBack size={15} className='flex items-center justify-center' />
                    <p className='font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center'>Go back</p>
                </div>

                <div className='h-[572px] text-[15px] leading-[25.5px] tracking-[-0.3px] justify-center mt-8 mx-4 md:mx-[10vw]'>
                    <span className='text-[20px] font-[600]'>Epagad</span> - Your Trusted Source for Healthcare and medical consumables. Explore our platform, which seamlessly integrates
                    an intuitive web interface, offering you a seamless and comprehensive shopping experience.


                    <p className='pt-10 pb-4 underline font-[500]'>How our app works</p>
                    &#x2022; Sellers sign up and create an account.

                    <br /> <br />
                    &#x2022; Sellers can post up to two products for free.

                    <br /> <br />
                    &#x2022; Sellers have the option to upgrade to a premium account, allowing them to post more products.

                    <br /> <br />
                    &#x2022; Buyers browse products listed on the website.

                    <br /> <br />
                    &#x2022; Buyers can view product details and contact information of sellers.

                    <br /> <br />
                    &#x2022; Buyers can message sellers to inquire about products or negotiate terms.

                    <br /> <br />
                    &#x2022; When buyers are satisfied with the product and seller, they arrange payment and delivery directly with the seller.




                    <p className='pt-10 pb-4 underline font-[500]'>Terms and Conditions</p>
                    &#x2022; Buyers must not make any payment until they have directly communicated with the seller, viewed the product, and are satisfied with both the product and the seller.

                    <br /> <br />
                    &#x2022; Sellers are responsible for accurately representing their products and providing honest information to buyers.

                    <br /> <br />
                    &#x2022; Sellers must comply with all relevant laws and regulations regarding the sale of their products.

                    <br /> <br />
                    &#x2022; Buyers and sellers are solely responsible for arranging payment, delivery, and any disputes that may arise from transactions.

                    <br /> <br />
                    &#x2022; The platform is not liable for any transactions conducted between buyers and sellers, including but not limited to the quality of products, payment disputes, or delivery issues.

                    <br /> <br />
                    &#x2022; The platform reserves the right to remove any listings or suspend accounts that violate these terms and conditions or engage in fraudulent or unethical behavior.

                    <br /> <br />
                    &#x2022; By using the platform, users agree to abide by these terms and conditions and acknowledge that the platform is not responsible for any transactions conducted through the platform.

                    <br /> <br />
                </div>

            </div>

        </React.Fragment>
    )
}

export default About