import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import {
  productCategoriesAtom,
  selectedCategoryAtom,
  showPopupAtom,
} from "../../Utils/atom";
import { useAtom, useAtomValue } from "jotai";
import Body from "./Body";
import Flag from "./Flag";
import Header1 from "./Header/Header1";
import Header2 from "./Header/Header2";
import Header3 from "./Header/Header3";
import Header4 from "./Header/Header4";
import Header5 from "./Header/Header5";
import NewsLetterModal from "./NewsLetterModal";

const Main = () => {
  const productCategories = useAtomValue(productCategoriesAtom);
  const [selectedCategory, setSelectedCategory] = useAtom(selectedCategoryAtom);
  const [selectedCategoryText, setSelectedCategoryText] = useState("");
  const showPopup = useAtomValue(showPopupAtom);

  const updateCategory = (category) => {
    setSelectedCategory(category);
    setSelectedCategoryText(category);
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden min-h-screen">
        <Header1 />
        <Header2 />
        <Header3 />
        <Header4 />
        <Header5 />

        <div className="flex">
          <Navbar />

          <div className="w-full">
            <Flag />

            <div className="ml-4 mt-8">
              <div className="flex justify-between items-center mb-2">
                {selectedCategory === "Services" ? (
                  <p className="font-[600] text-[20px] md:text-[20px]">
                    Available Services
                  </p>
                ) : (
                  <p className="font-[600] text-[20px] md:text-[20px]">
                    Available Products
                  </p>
                )}

                {/* dropdown menu for categories on smaller screens */}
                <div className="md:hidden">
                  <select
                    name="categories"
                    className="border border-[#8092A3] w-32 mr-4 h-[24px] flex items-center justify-center text-[10px]"
                    value={selectedCategoryText}
                    onChange={(e) => updateCategory(e.target.value)}
                  >
                    <option value="All categories">All categories</option>
                    {productCategories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Body />
            </div>
          </div>
        </div>
      </div>

      <NewsLetterModal />
    </React.Fragment>
  );
};

export default Main;
