import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { VscAccount } from "react-icons/vsc";
import { isAuthenticatedAtom, productsAtom, searchQueryAtom, searchResultAtom } from '../../../Utils/atom';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate, useLocation } from 'react-router-dom';
import { isAdminAuthenticatedAtom, sellersAtom } from '../../Admin/utils/atoms';


const Header3 = () => {
    const isAuthenticated = useAtomValue(isAuthenticatedAtom);
    const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom)
    const [searchResult, setSearchResult] = useAtom(searchResultAtom)
    const sellers = useAtomValue(sellersAtom)
    const products = useAtomValue(productsAtom)
    const location = useLocation()

    const handleProductSearch = (e) => {
        e.preventDefault();

        const lSearchQuery = searchQuery.toLowerCase()
        const searchedProducts = products.filter(product => {
            const lDescription = product.description.toLowerCase()
            const lCategory = product.category.toLowerCase()

            return lDescription.includes(lSearchQuery) || lCategory.includes(lSearchQuery)
        });

        const approvedProducts = searchedProducts.filter(product => product.approved === true)
        setSearchResult(approvedProducts ? approvedProducts : [])
    }

    const handleSellerSearch = (e) => {
        e.preventDefault()

        const lSellers = searchQuery.toLowerCase()
        const searchedSellers = sellers.filter(seller => {
            const lFullname = seller.fullname.toLowerCase()
            return lFullname.includes(lSellers)
        })

        setSearchResult(searchedSellers ? searchedSellers : [])
    }

    return (
        <React.Fragment>
            <div className='left-0 right-0 z-50'>

                <div className='bg-[#722f37] flex justify-between px-4 md:px-20 items-center py-4'>
                    <Link to='/' className='text-white text-[18px] md:text-[26px] font-bold'>Epagad</Link>

                    <form onSubmit={location.pathname === '/control-panel/07D32q/admin/view-sellers' ? handleSellerSearch : handleProductSearch}>
                        <div className="flex gap-1 md:gap-3">
                            <input
                                type="text"
                                value={searchQuery}
                                placeholder="Search for what you need..."
                                autoComplete="on"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className={`bg-white ${isAuthenticated ? 'w-[200px]' : 'ml-2 w-[60vw]'} md:w-[300px] lg:w-[570px] h-[34px] md:h-[40px] placeholder:flex placeholder:font-[400] placeholder:text-[12px] md:placeholder:text-[14px] placeholder:text-gray-400 text-[12px] p-2 rounded-none`}
                            />
                            <button
                                type='submit'
                                className="bg-white h-[34px] md:h-[40px] text-[8px] md:text-[12px] px-1 md:px-4">
                                <p className='text-[#722f37] font-[500]'>Search</p>
                            </button>
                        </div>
                    </form>

                    <div>
                        {isAuthenticated && (
                            <Link to='/account'>
                                <VscAccount className='text-white text-[18px] md:text-[20px] md:hidden' />
                                <p className='text-white underline hidden md:flex'>My Account</p>
                            </Link>
                        )}
                    </div>

                </div>
            </div>

        </React.Fragment >
    )
}

export default Header3