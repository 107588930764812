import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { getAccessToken } from '../../Utils/Cookies';
import toast, { Toaster } from "react-hot-toast";
import { useAtomValue } from 'jotai';
import { productCategoriesAtom, priceNegotiabilityAtom, productStatusAtom, productsAtom, paymentTypeAtom } from '../../Utils/atom';
import axios from 'axios';
import { useAtom } from 'jotai';
import Payment from '../Payment/PaystackPayment';
import ButtonLoader from '../General/ButtonaLoader';
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';
import Header3 from '../Landing/Header/Header3';
import Header4 from '../Landing/Header/Header4';
import { useNavigate } from 'react-router-dom';


const AddProduct = () => {
  const userDataString = localStorage.getItem('userData');
  const userData = JSON.parse(userDataString);
  const sellerID = userData._id
  const isPremiumSubscriber = userData.premium
  const productCategories = useAtomValue(productCategoriesAtom)
  const productStatusEnums = useAtomValue(productStatusAtom)
  const productNegotiability = useAtomValue(priceNegotiabilityAtom)
  const products = useAtomValue(productsAtom)
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [negotiableValue, setNegotiableValue] = useState("")
  const [negotiable, setNegotiable] = useState(false)
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [quantity, setQuantity] = useState("");
  const [response, setResponse] = useState("")
  const [category, setCategory] = useState("")
  const [images, setImages] = useState("");
  const accessToken = getAccessToken()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const [paymentType, setPaymentType] = useAtom(paymentTypeAtom)

  const productAddedSuccess = () => toast.success('Your advert placement is successful but awaiting approval', { duration: 8000, id: 'product_added_success', position: 'bottom-center' })
  const productAddedFailure = () => toast.error('An error occured', { duration: 7000, id: 'product_added_failure' })



  const nigerianStates = [
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa',
    'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
    'Ekiti', 'Enugu', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano',
    'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa', 'Niger',
    'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto',
    'Taraba', 'Yobe', 'Zamfara', 'Abuja',
  ];

  const handleImageUpload = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    const imagesArray = Array.from(files);
    imagesArray.forEach((file) => {
      setImages(prevImages => [...prevImages, file]);
    });
  };

  const sellerProducts = products.filter(product => product.seller === sellerID);

  useEffect(() => {
    if (negotiableValue === "Negotiable") {
      setNegotiable(true)
    }
    else {
      setNegotiable(false)
    }
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonClicked(true)
    setIsButtonLoading(true)

    if (images.length === 0) {
      setIsButtonLoading(false)
      setButtonClicked(false)
      setResponse("Please select at least one image.");
      return;
    }
    if (images.length < 1) {
      setIsButtonLoading(false)
      setButtonClicked(false)
      setResponse("Please select at least 1 image");
      return;
    }

    const formData = new FormData();
    formData.append('description', description);
    formData.append('price', price);
    formData.append('negotiable', negotiable);
    formData.append('status', status);
    formData.append('location', location);
    formData.append('quantity', quantity);
    formData.append('category', category);
    images.forEach((image) => {
      formData.append(`images`, image);
    });

    try {
      const response = await axios.post('https://epagad.fly.dev/product', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`
        },
      });
      console.log(response)


      if (response.status === 201) {
        productAddedSuccess()
        setIsButtonLoading(false)
        setResponse("Your advert placement is successful but awaiting approval")
        setDescription("")
        setPrice("")
        setNegotiableValue("")
        setStatus("")
        setLocation("")
        setQuantity("")
        setCategory("")
        setImages("")
        //location.reload()


      }
    }
    catch (error) {
      productAddedFailure()
      setIsButtonLoading(false)
      setButtonClicked(false)
      setResponse("Product was not added, please try again.")
      console.log("error uploading product: ", error)
    }
  };


  return (
    <div className="flex flex-col">
      <Toaster />
      <Header1 />
      <Header2 />
      <Header3 />
      <Header4 />


      <div className="flex md:flex-row md:space-x-4">
        <div className='hidden md:flex '>
          <Navbar />
        </div>


        <div className='w-full'>
          <div className="md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit h-fit relative">

            <div className='flex justify-between border-b py-2 px-4 font-semibold'>
              <p className="">New item</p>

              <div className='md:hidden absolute top-0 right-0'>
                <Navbar />
              </div>
            </div>

            {sellerProducts.length >= 2 && isPremiumSubscriber === false ? (
              <div className='flex flex-col items-center justify-center'>
                <p className='mt-12 md:mt-20 px-4 text-[14px] text-center'>
                  You cannot add more products unless you are subscribed to a premium account. <br /> <br />

                  Subscribe to premium by making a monthly payment of <span className='font-[600] text-black'>&#8358;5000</span> or a yearly payment of <span className='font-[600] text-black'>&#8358;55,000</span> <br />
                  <div className='flex flex-col justify-center items-center'>
                    <h4>    Note: Payment can be made in any  <b>currency</b>. </h4>
                    <h4>     Note: This <b>payment is a one-time</b> payment.   </h4>
                  </div>


                </p>

                <div className='flex gap-6 mt-12 mb-6'>
                  <button
                    onClick={() => setPaymentType("Monthly")}
                    className={`border border-[#722f37] p-2 ${paymentType === "Monthly" ? 'text-[#722f37]' : ''} text-[13px] md:text-[15px]`}
                  >
                    Pay &#8358;5000/month
                  </button>

                  <button
                    onClick={() => setPaymentType("Yearly")}
                    className={`border border-[#722f37] p-2  ${paymentType === "Yearly" ? 'text-[#722f37]' : ''} text-[13px] md:text-[15px]`}
                  >
                    Pay &#8358;55,000/year
                  </button>
                </div>

                {paymentType && (
                  <div className='text-white my-4 bg-green-500 hover:bg-green-800 duration-300 text-[13px] p-2 w-fit:'>
                    <Payment />
                  </div>
                )}
              </div>


            ) : (
              <div>
                {response && <p className='ml-2 text-[10px] md:text-[11px] text-[#722f37] p-2 font-[600] md:max-w-[50vw]'>{response}</p>}
                <form className={`mt-4 p-4 md:w-[470px] lg:w-[680px] md:mx-auto`} onSubmit={handleSubmit}>

                  <label className="block text-sm font-semibold mb-1">Description:</label>
                  <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoComplete="on"
                    required
                    className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                  />


                  <label className="block text-sm font-semibold mb-1">Price &#8358; :</label>
                  <input
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                  />


                  <label className="block text-sm font-semibold mb-1">Price Negotiability:</label>
                  <select name='negotiability'
                    className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    value={negotiableValue}
                    onChange={(e) => setNegotiableValue(e.target.value)}
                    disabled={!price}
                  >
                    <option value=""></option>
                    {productNegotiability.map((value) => (
                      <option
                        key={value}
                        value={value}>
                        {value}
                      </option>
                    ))}
                  </select>


                  <label className="block text-sm font-semibold mb-1">Status:</label>
                  <select name='status'
                    className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {productStatusEnums.map((status) => (
                      <option
                        key={status}
                        value={status}>
                        {status}
                      </option>
                    ))}
                  </select>


                  <label className="block text-sm font-semibold mb-1">Category:</label>
                  <select name='category'
                    className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {productCategories.map((category) => (
                      <option
                        key={category}
                        value={category}>
                        {category}
                      </option>
                    ))}
                  </select>


                  <label className="block text-sm font-semibold mb-1">Location of availability:</label>
                  <select name='location'
                    className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                  >
                    <option value=""></option>
                    {nigerianStates.map((value) => (
                      <option
                        key={value}
                        value={value}>
                        {value}
                      </option>
                    ))}
                  </select>


                  <label className="block text-sm font-semibold mb-1">Quantity in stock:</label>
                  <input

                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                    className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black placeholder:text-sm text-[13px]"
                  />


                  <label htmlFor='file-upload' className="block text-sm font-semibold mb-1 items-center">Images: <span className='text-[#722f37] text-[8px] md:text-[10px]'>Please select quality images carefully.</span></label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple
                    className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                    required
                  />


                  <div className='mt-10 mb-6'>
                    <button
                      type='submit'
                      disabled={buttonClicked}
                      className='bg-[#722f37] text-white w-full h-[43px] flex items-center justify-center text-[15px] font-[500] mt-10 disabled:cursor-not-allowed'>
                      {isButtonLoading ? <ButtonLoader /> : <p>Place Advert</p>}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
