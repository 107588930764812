import React from 'react';
import Navbar from './Navbar';
import { useAtomValue } from 'jotai';
import { productsAtom } from '../../Utils/atom';
import { useNavigate } from 'react-router-dom';
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';
import Header3 from '../Landing/Header/Header3';
import Header4 from '../Landing/Header/Header4';



// this component filters and displays the products of a single seller
const ViewProducts = () => {
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const sellerID = userData._id
    const products = useAtomValue(productsAtom)
    const navigate = useNavigate()

    const sellerProducts = products.filter(product => product.seller === sellerID || product.owner === sellerID);

    const NavigateToProductDetail = (productId) => {
        localStorage.setItem('productId', productId);
        navigate(`/products/${productId}/detail`);
    }

    const NavigateToEdit = (productId) => {
        localStorage.setItem('productId', productId)

        navigate(`/products/${sellerID}/view-all/${productId}/edit`)
    }



    return (
        <div className='min-h-screen'>
            <div className="flex flex-col">
                <Header1 />
                <Header2 />
                <Header3 />
                <Header4 />

                <div className="flex md:flex-row md:space-x-4">
                    <div className='hidden md:flex '>
                        <Navbar />
                    </div>

                    <div className="relative w-full">

                        <div className='flex items-center justify-between py-2 px-4 font-semibold'>
                            <p className="">Listed products <span className='text-[10px] text-[#722f37]'>(refresh page to see new products)</span></p>

                            <div className='md:hidden absolute top-0 right-0'>
                                <Navbar />
                            </div>
                        </div>


                        <div>
                            {sellerProducts && sellerProducts.length > 0 ?
                                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 overflow-hidden gap-3 mr-4 ml-4 mt-6'>

                                    {sellerProducts.map((product, index) => (
                                        <div key={index} className='w-full cursor-pointer'>
                                            <div className='border shadow-sm shadow-gray-600 mb-3 object-cover h-fit'>
                                                {product.images && product.images[0] && (
                                                    <img
                                                        src={product.images[0]}
                                                        style={{
                                                            objectFit: 'cover',
                                                            width: '100%',
                                                            height: '130px',
                                                        }}
                                                        className='object-cover w-full h-[110px]'
                                                        alt={`apartment ${index + 1}`}
                                                    />
                                                )}

                                                <div className='bg-white h-fit border-t'>
                                                    <div className='mt-2 mx-2'>
                                                        <div className='h-[30px]'>
                                                            <p className='text-[10px] md:text-[13px] truncate max-h-[38px] font-[500]'>
                                                                {product.description}
                                                            </p>
                                                        </div>

                                                        <div className='flex'>
                                                            <p className='text-[10px] text-gray-500 font-[500]'>{product.category}</p>
                                                        </div>

                                                        <div className=''>
                                                        {product.price ? (
                                                        
                                                        <p className='text-[13px] md-[11px] font-[500] text-[#722f37]'>&#8358; {product.price}</p>
                                                        ) : <p className='text-[13px] md-[11px] font-[500] text-[#722f37]'>&#8358; {product.priceRange}</p> } 
                                                        </div>


                                                        <div className='my-2 flex justify-between'>
                                                            <button className='text-black text-[12px] underline' onClick={() => NavigateToProductDetail(product._id)}>
                                                                Expand
                                                            </button>

                                                            {product.approved === true  && product.category !== "service" ? (
                                                                <button className='text-white bg-[#722f37] font-semibold py-1 px-3 text-[12px]' onClick={() => NavigateToEdit(product._id)}>
                                                                    Edit
                                                                </button>
                                                            ): null}
                                                            
                                                            {product.category !== "service" ? (
                                                                <p className='my-2 text-[#722f37] text-[10px] md:text-[8px] lg:text-[10px] font-[600] flex items-end justify-end cursor-not-allowed'>Awaiting approval</p>
                                                            ): null}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <p className='flex items-center justify-center mt-24 bg-none shadow-none border-none text-sm'>You don't have any products listed yet.</p>
                            }
                        </div>


                    </div>
                </div>
            </div>
        </div >
    )
}

export default ViewProducts