import * as yup from 'yup'

const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
// minimum of 6 characters, 1 uppercase letter, 1 lowercase letter, 1 symbol, 1 numeric value

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

export const signupSchema = yup.object().shape({
    fullname: yup
        .string()
        .required('Required'),

    email: yup
        .string()
        .email("Please enter a valid email")
        .matches(emailRegex)
        .required("Required"),

    password: yup
        .string()
        .min(6)
        .matches(passwordRegex, { message: "Please create a stronger password" })
        .required("Required"),

    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords must match")
        .required('Required'),

    location: yup
        .string()
        .required('Required'),

    phoneNumber: yup
        .string()
        .required('Required')
})


export const passwordResetSchema = yup.object().shape({
    password: yup
        .string()
        .min(6)
        .matches(passwordRegex, { message: "Please create a stronger password" })
        .required("Required"),

    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords must match")
        .required('Required'),
})
