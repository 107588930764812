import { atom } from "jotai";
import { getAccessToken } from "./Cookies";

export const productsAtom = atom([]);
export const productIdAtom = atom(0);
export const productAtom = atom({});
export const productCategoriesAtom = atom([
  "Baby and Maternity Products",
  "Dental",
  "Dental Care Products",
  "Diagnostic",
  "Diagnostic Equipment",
  "Eye Care Products",
  "First Aid Supplies",
  "Gym Products",
  "Health Supplements",
  "Home Health Care",
  "Hygiene and Sanitary Products",
  "Laboratory",
  "Medical",
  "Medical Devices",
  "Mobility Aids",
  "Nutritional Products",
  "Orthopedic Products",
  "order",
  "Personal Protective Equipment (PPE)",
  "Physiotherapy",
  "Rehabilitation and Therapy Products",
  "Scientific",
  "Services",
  "Surgical",
  "Teaching and Research",
  "Other",
]);

export const productStatusAtom = atom([
  "BrandNew",
  "ForeignUsed",
  "LocallyUsed",
]);
export const priceNegotiabilityAtom = atom(["Negotiable", "Not Negotiable"]);
export const selectedCategoryAtom = atom("All categories");
export const isAuthenticatedAtom = atom(getAccessToken !== null);
export const productOwner = atom([]);
export const searchQueryAtom = atom("");
export const searchResultAtom = atom([]);
export const showPopupAtom = atom(false);
export const paymentTypeAtom = atom("");
export const paymentReferenceAtom = atom("");
