import React, { useState, useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { productCategoriesAtom, productStatusAtom, productsAtom } from '../../Utils/atom'
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import { getAccessToken } from '../../Utils/Cookies'
import Loader from '../General/Loader'
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';
import Header3 from '../Landing/Header/Header3';
import Header4 from '../Landing/Header/Header4';

const EditProduct = () => {
    const productCategories = useAtomValue(productCategoriesAtom)
    const productStatusEnums = useAtomValue(productStatusAtom)
    const productId = localStorage.getItem('productId')
    const products = useAtomValue(productsAtom)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [response, setResponse] = useState("")
    const accessToken = getAccessToken()
    const navigate = useNavigate()

    const nigerianStates = [
        'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa',
        'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
        'Ekiti', 'Enugu', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano',
        'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa', 'Niger',
        'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto',
        'Taraba', 'Yobe', 'Zamfara', 'Abuja',
    ];

    // retrieve selected product
    useEffect(() => {
        const filteredProduct = products.filter(p => p._id === productId);
        setSelectedProduct(filteredProduct.length > 0 ? filteredProduct[0] : null);

    }, [products, productId]);
    if (!selectedProduct) return <Loader />

    const handleDataChange = (e) => {
        const { name, value } = e.target
        setSelectedProduct(prevProduct => ({
            ...prevProduct,
            [name]: value
        }));
    };

    const UpdateProduct = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
            const response = await fetch(`https://epagad.fly.dev/product?id=${productId}`, {
                method: 'PATCH',
                headers: headers,
                body: JSON.stringify({
                    description: selectedProduct.description,
                    price: selectedProduct.price,
                    status: selectedProduct.status,
                    category: selectedProduct.category,
                    location: selectedProduct.location,
                    quantity: selectedProduct.quantity
                }),
            });

            if (response.status === 200) {
                setIsButtonLoading(false)
                setResponse(`Product ${productId} has been updated successfully`)
            }
        } catch (error) {
            setIsButtonLoading(false)
            setResponse('Product was not updated, please try again.');
        }
    }

    return (
        <React.Fragment>
            <div className="flex flex-col">
                <Header1 />
                <Header2 />
                <Header3 />
                <Header4 />

                <div className="flex md:flex-row md:space-x-4">
                    <div className='hidden md:flex '>
                        <Navbar />
                    </div>

                    <div className="md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit h-fit relative">

                        <div className='flex justify-between border-b py-2 px-4 font-semibold'>
                            <p className="">Edit product</p>

                            <div className='md:hidden absolute top-0 right-0'>
                                <Navbar />
                            </div>
                        </div>


                        {response &&
                            <p className={`ml-2 text-[10px] p-2 font-[600] ${response === `Product ${productId} has been updated successfully` ?
                                'text-green-600'
                                :
                                'text-red-700'}`}
                            >
                                {response}
                            </p>
                        }
                        <form className="mt-4 p-4 md:w-[470px] lg:w-[680px] md:mx-auto" onSubmit={UpdateProduct}>

                            <label className="block text-sm font-semibold mb-1">Description:</label>
                            <input
                                type="text"
                                name='description'
                                value={selectedProduct.description}
                                onChange={handleDataChange}
                                autoComplete="on"
                                required
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                            />

                            <label className="block text-sm font-semibold mb-1">Price:</label>
                            <input
                                type="text"
                                name='price'
                                value={selectedProduct.price}
                                onChange={handleDataChange}
                                autoComplete="on"
                                required
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                            />


                            <label className="block text-sm font-semibold mb-1">Status:</label>

                            <select
                                name='status'
                                className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                                value={selectedProduct.status}
                                onChange={handleDataChange}
                                required
                            >
                                <option value=""></option>
                                {productStatusEnums.map((status) => (
                                    <option
                                        key={status}
                                        value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>


                            <label className="block text-sm font-semibold mb-1">Category:</label>

                            <select name='category'
                                className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                                value={selectedProduct.category}
                                onChange={handleDataChange}
                                required
                            >
                                <option value=""></option>
                                {productCategories.map((category) => (
                                    <option
                                        key={category}
                                        value={category}>
                                        {category}
                                    </option>
                                ))}
                            </select>


                            <label className="block text-sm font-semibold mb-1">Location of availability:</label>
                            <select name='location'
                                className="w-full px-2 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black text-[13px]"
                                value={selectedProduct.location}
                                onChange={handleDataChange}
                                required
                            >
                                <option value=""></option>
                                {nigerianStates.map((value) => (
                                    <option
                                        key={value}
                                        value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>

                            <label className="block text-sm font-semibold mb-1">Quantity in stock:</label>
                            <input
                                type="text"
                                name='quantity'
                                value={selectedProduct.quantity}
                                onChange={handleDataChange}
                                autoComplete="on"
                                required
                                className="w-full px-3 py-2 border border-gray-300 mb-3 focus:outline-none focus:border-black placeholder:text-sm text-[13px]"
                            />

                            <div className='mt-10 mb-6'>
                                {isButtonLoading ? (
                                    <div className='flex items-center justify-center bg-[#722f37] md:w-[650px] h-[43px] text-white'>
                                        <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white'></div>
                                    </div>
                                ) : (
                                    <button
                                        type='submit'
                                        className='bg-[#722f37] text-white w-full h-[43px] flex items-center justify-center text-[15px] font-[500] mt-10'>
                                        Save changes
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </React.Fragment >
    );
}

export default EditProduct