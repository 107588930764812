import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { getAdminAccessToken } from './Cookies'
import { useAtomValue } from 'jotai';
import { isAdminAuthenticatedAtom } from './atoms';


const ProtectedAdminRoutes = () => {
  const isAuthenticated = useAtomValue(isAdminAuthenticatedAtom);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedAdminRoutes