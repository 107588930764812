import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";


const Header2 = () => {
  return (
    <React.Fragment>
      <div className='left-0 right-0 z-50'>
        <div className='bg-[#722f37] flex justify-between px-4 md:px-20 items-center py-3 border-b border-b-gray-500'>
          <p className='text-white text-[10px] max-w-[50vw] md:max-w-full md:text-[10px] lg:text-[13px]'>We will be glad to have you market your products, services and skills on our platform!</p>

          <div className='flex gap-2 text-white'>
            <p className='text-[10px] md:text-[13px]'>Follow us:</p>
            <div className='flex items-center gap-3 hover:text-gray-300'>
              <a href='' className='hover:text-gray-300 duration-300'>
                <FaInstagram />
              </a>
              <a href='' className='hover:text-gray-300 duration-300'>
                <FaTwitter />
              </a>
              <a href='' className='hover:text-gray-300 duration-300'>
                <FaFacebook />
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default Header2
