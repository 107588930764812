import React, { useEffect, useState } from 'react'
import { FaKey } from "react-icons/fa6";
import { BiSolidLockAlt } from 'react-icons/bi'
import { GiConfirmed } from "react-icons/gi";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ButtonLoader from '../General/ButtonaLoader';
import Header1 from '../Landing/Header/Header1';
import Header2 from '../Landing/Header/Header2';


const ResetPassword = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [code, setCode] = useState("")
    const [email, setEmail] = useState("")
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [response, setResponse] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)


    const [errors, setErrors] = useState("")
    const [values, setValues] = useState({
        password: "",
        confirmPassword: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });

        if (name === 'password' && value.length < 6) {
            setErrors({ ...errors, password: 'Password must be at least 6 characters long' });
        } else {
            setErrors({ ...errors, password: '' });
        }
    };

    useEffect(() => {
        const retrieveParams = () => {
            setCode(searchParams.get("code"))
            setEmail(searchParams.get("seller"))
        }
        retrieveParams()
    }, [code, email])

    const UpdateAccount = async (e) => {
        e.preventDefault()
        setIsButtonLoading(true)

        if (values.confirmPassword !== values.password) {
            setIsButtonLoading(false)
            setResponse('Passwords must match');
            return;
        }
        if (email) {
            try {
                const response = await axios.patch(
                    "https://epagad.fly.dev/password/update-password",
                    {
                        email: email,
                        password: values.password,
                        code: code
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                setIsButtonLoading(false)
                setResponse("Your password has been reset. Please sign in.")
            }
            catch (error) {
                setIsButtonLoading(false)
                setResponse("An error occurred, please try again later.")

                if (error.response) {
                    if (error.response.status === 404) {
                        setResponse("Password reset link is no longer valid.")
                    }
                }
            }
        }
    }

    return (
        <div>
            <Header1 />
            <Header2 />


            <div className='md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit flex flex-col mx-auto mt-10'>
                <div className='flex gap-2 md:text-[18px] font-[500] items-center justify-start md:mt-5 pl-6'>
                    <FaKey className='text-[#722f37]' />
                    <p>Reset Password</p>
                </div>

                <div className='border-gray-200 mt-4 border mx-6' />

                <p className='font-[300] mt-5 text-[12px] mx-6'>
                    Enter a new password to reset the password on your account. We'll ask for this password whenever you log in.
                </p>

                {response && <p className='text-center text-[#722f37] font-[500] mx-4 md:mx-0 mt-3 mb-3 text-sm'>{response}</p>}

                <form method='POST' onSubmit={UpdateAccount}>
                    <div className='mt-8'>
                        <div className='flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] '>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='New password'
                                name='password'
                                value={values.password}
                                onChange={handleChange}
                                autoComplete='off'
                                autoCorrect='off'
                                required
                                className='w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border'
                            />

                            <div className='bg-[#722f37] w-12 flex items-center justify-center'>
                                <BiSolidLockAlt className='text-white flex' size={25} onClick={() => setShowPassword(!showPassword)} />
                            </div>
                        </div>
                        {errors.password && <p className="text-red-500">{errors.password}</p>}


                        <div className='flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] '>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder='Confirm new password'
                                name='confirmPassword'
                                value={values.confirmPassword}
                                onChange={handleChange}
                                autoComplete='off'
                                autoCorrect='off'
                                required
                                className='w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border'
                            />

                            <div className='bg-[#722f37] w-12 flex items-center justify-center'>
                                <GiConfirmed className='text-white' size={25} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                            </div>
                        </div>
                    </div>

                    <div className='mt-6 mx-6 mb-6'>
                        <button
                            type='submit'
                            className='bg-[#722f37] text-white w-full md:w-[650px] h-[43px] flex items-center justify-center text-[15px] font-[500]'>
                            {isButtonLoading ? <ButtonLoader /> : <p>Submit</p>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword