import React, { useState, useEffect } from "react";
import { useAtomValue } from "jotai";
import {
  productsAtom,
  searchQueryAtom,
  searchResultAtom,
  selectedCategoryAtom,
} from "../../Utils/atom";
import { useNavigate } from "react-router-dom";
import Loader from "../General/Loader";

const Body = () => {
  const products = useAtomValue(productsAtom);
  const [feedProducts, setFeedProducts] = useState([]);
  const selectedCategory = useAtomValue(selectedCategoryAtom);
  const searchResult = useAtomValue(searchResultAtom);
  const searchQuery = useAtomValue(searchQueryAtom);
  var filteredProducts = useState([]);
  let serviceProducts = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCategory === "All categories") {
      let actualProducts = products.filter(
        (product) => product.category !== "service",
      );
      filteredProducts = actualProducts;
    } else if (selectedCategory === "Services") {
      serviceProducts = products.filter(
        (service) => service.category === "service",
      );
      filteredProducts = serviceProducts;
    } else {
      filteredProducts = products.filter(
        (p) => p.category === selectedCategory,
      );
    }

    if (!filteredProducts || !filteredProducts.length < 0) return <Loader />;

    setFeedProducts(filteredProducts);
  }, [products, selectedCategory]);

  if (searchQuery.length > 0 && (!searchResult || searchResult.length === 0)) {
    return (
      <div className="flex items-center justify-center h-full mt-10">
        <p className="text-center text-gray-500 text-[12px] md:text-[14px]">
          Sorry, we couldn't find what you're looking for.
        </p>
      </div>
    );
  }

  const NavigateToProductDetail = (productId) => {
    localStorage.setItem("productId", productId);
    navigate(`/products/${productId}/detail`);
  };

  const approvedProducts = feedProducts
    .filter((p) => p.approved === true)
    .reverse();
  const displayedProducts = searchQuery ? searchResult : approvedProducts;

  return (
    <React.Fragment>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 overflow-hidden gap-3 mr-4">
        {displayedProducts.map((product, index) => (
          <div
            key={index}
            className="w-full cursor-pointer"
            onClick={() => NavigateToProductDetail(product._id)}
          >
            <div className="border shadow-sm shadow-gray-600 mb-3 object-cover h-fit">
              {product.images && product.images[0] && (
                <img
                  src={product.images[0]}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "130px",
                  }}
                  className="object-cover w-full h-[110px]"
                  alt={`apartment ${index + 1}`}
                />
              )}

              <div className="bg-white h-fit border-t">
                <div className="py-3 mx-2">
                  <div className="h-[30px]">
                    <p className="text-[10px] md:text-[13px] truncate max-h-[38px] font-[500]">
                      {product.description.charAt(0).toUpperCase() +
                        product.description.slice(1)}
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-[10px] text-gray-500 font-[500]">
                      {product.category}
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-[10px] text-gray-500 font-[500] my-2 ">
                      {new Date(product.createdAt).toLocaleDateString("en-US")}
                    </p>
                  </div>

                  <div>
                    <p className="text-[12px] font-[500] text-[#722f37]">
                      {product.price
                        ? <h3>&#8358;</h3> && product.price
                        : product.priceRange
                        ? null
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Body;
