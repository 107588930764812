import React, { useState, useEffect } from 'react'
import flag from '../../assets/flag.jpeg'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'


const Flag = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const flagImages = [
    "https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://res.cloudinary.com/dbd7rcwwx/image/upload/v1711474087/pplepg_mihggb.jpg",
    "https://images.pexels.com/photos/3992946/pexels-photo-3992946.jpeg?auto=compress&cs=tinysrgb&w=800",
    "https://images.pexels.com/photos/305567/pexels-photo-305567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://res.cloudinary.com/dbd7rcwwx/image/upload/v1711473995/person2fg_jwstwy.jpg",
    "https://images.pexels.com/photos/397978/pexels-photo-397978.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  ]

  useEffect(() => {
    const intervalId = setInterval(goToNextSlide, 9000); // Change slide every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentImageIndex]);


  const goToPrevSlide = () => {
    setCurrentImageIndex((prevIndex) => (
      prevIndex === 0 ? flagImages.length - 1 : prevIndex - 1
    ));
  };
  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (
      prevIndex === flagImages.length - 1 ? 0 : prevIndex + 1
    ));
  };
  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };


  return (
    <div>
      <div className='w-[100%] h-[300px]'>
        <div className="relative h-[300px]">

          {flagImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={`absolute w-full h-full object-cover cursor-pointer transition-opacity ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'}`}
            />
          ))}


          <button
            className="w-[47.81px] h-[47.81px] absolute top-1/2 left-0 transform -translate-y-1/2 hover:bg-gray-200 duration-500 z-10 flex items-center justify-center m-4"
            onClick={goToPrevSlide}
          >
            <IoIosArrowBack size={25} className="text-black bg-opacity-20 flex items-center justify-center" />
          </button>

          <button
            className="w-[47.81px] h-[47.81px] absolute top-1/2 right-0 transform -translate-y-1/2 hover:bg-gray-200 duration-500 z-10 flex items-center justify-center m-4"
            onClick={goToNextSlide}
          >
            <IoIosArrowForward size={25} className="text-black bg-opacity-20 flex items-center justify-center" />
          </button>


          <div className=" flex items-center justify-center z-50 h-[300px] mx-auto">
            <p className="relative text-center text-[15px] md:text-[20px] lg:text-[24px] font-[650] text-black max-w-[50vw] mx-auto">
              Epagad is a one-stop marketplace for all your medical and health related equipment, instruments, and consumables <br />
              <span className="font-[650] lg:text-[28px] text-[#722f37]">
                ….buyers meet sellers here…!
              </span>
            </p>
          </div>
        </div>


        <div className="relative left-0 right-0 flex justify-center -mt-8">
          {flagImages.map((_, index) => (
            <button
              key={index}
              className={`mx-2 w-3 h-3 rounded-full duration-500 bg-gray-300 focus:outline-none focus:bg-white ${index === currentImageIndex ? 'bg-gray-400' : ''}`}
              onClick={() => goToSlide(index)}
            ></button>
          ))}
        </div>
      </div>
    </div>

  )
}

export default Flag
