import React, { useEffect, useState } from 'react'
import { MdOutlineMailOutline } from "react-icons/md";
import { BiSolidLockAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isAuthenticatedAtom } from '../../Utils/atom';
import { storeAccessToken, getAccessToken } from '../../Utils/Cookies';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import ButtonLoader from '../General/ButtonaLoader';


const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom)
    const navigate = useNavigate()


    // fetching signin api
    const Signin = async (e) => {
        e.preventDefault()
        setIsButtonLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
            }

            const response = await fetch('https://epagad.fly.dev/seller/login', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            if (response.status === 200) {
                setIsButtonLoading(false)
                const responseData = await response.json();
                storeAccessToken(responseData.token)

                // store user data in localStorage
                const userData = responseData.data
                const userDataString = JSON.stringify(userData)
                localStorage.setItem('userData', userDataString)

                navigate('/')
            }
            else {
                setIsButtonLoading(false)
                setErrorMessage('Invalid email or password. Please try again.')
            }
        }
        catch (error) {
            setIsButtonLoading(false)
            setErrorMessage("An error occurred. Please try again later.")
        }

    };

    const updateAuthenticationState = () => {
        const accessToken = getAccessToken();
        setIsAuthenticated(accessToken !== null);
    };

    useEffect(() => {
        updateAuthenticationState();
    }, [setIsAuthenticated]);


    return (
        <div className=''>
            <div className='flex items-center gap-2 cursor-pointer ml-4 mt-4 md:hidden' onClick={() => navigate(-1)}>
                <IoIosArrowBack size={15} className='flex items-center justify-center' />

                <p className='font-[500] text-[15px] leading-[30px] tracking-[-0.3px] flex items-center justify-center'>Go back</p>
            </div>


            <div className='flex flex-col items-center justify-center h-screen'>
                <div className='md:shadow-sm md:shadow-gray-600 md:border w-full md:w-fit'>
                    <Link to='/' className='text-[#722f37] flex items-center justify-center mt-4 mb-2 font-[600] text-[32px]'>Epagad</Link>


                    {errorMessage && <p className='text-center text-[#722f37] font-[500] mt-3 mb-3 text-sm'>{errorMessage}</p>}
                    <form method='POST' onSubmit={Signin}>
                        <div className='mt-8'>
                            <div className='flex flex-row justify-between mx-6 border mb-6 md:w-[650px] h-[43px] '>
                                <input
                                    type='email'
                                    placeholder='Email Address'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete='on'
                                    required
                                    className='w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border'
                                />

                                <div className='bg-[#722f37] w-12 flex items-center justify-center'>
                                    <MdOutlineMailOutline className='text-white' size={25} />
                                </div>
                            </div>


                            <div className='flex flex-row justify-between mx-6 border mb-4 md:w-[650px] h-[43px] '>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete='off'
                                    autoCorrect='off'
                                    required
                                    className='w-full md:w-[602px] text-[13px] rounded-[0.150rem] text-black px-3 py-0 placeholder:text-gray-300 border'
                                />

                                <div className='bg-[#722f37] w-12 flex items-center justify-center'>
                                    <BiSolidLockAlt className='text-white flex' size={25} onClick={() => setShowPassword(!showPassword)} />
                                </div>
                            </div>

                        </div>

                        <p className='font-[400] text-[15px] leading-4 tracking-tight text-end pr-6'>
                            <Link
                                to='/forgot-password'
                                className='text-[#722f37] font-[500] text-[13px] tracking-tight hover:text-[#080e13]'> Forgot Your Password?
                            </Link>
                        </p>

                        <div className='mt-6 mx-6 mb-6'>
                            <button
                                type='submit'
                                className='bg-[#722f37] text-white w-full md:w-[650px] h-[43px] flex items-center justify-center text-[15px] font-[500]'>
                                {isButtonLoading ? <ButtonLoader /> : <p>Sign in</p>}
                            </button>
                        </div>

                        <p className='font-[400] text-[15px] leading-4 tracking-tight text-center pb-3'>
                            Don't have an account?
                            <span>
                                <Link
                                    to='/signup'
                                    className='text-[#722f37] font-[500] text-[13px] leading-4 tracking-tight hover:text-[#080e13]'> Signup
                                </Link>
                            </span>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login